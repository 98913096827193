import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { buildUrl } from "../utils/url";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Login() {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const url = buildUrl(process.env.REACT_APP_API_URL, "auth/login");
    console.log("🔄 Intentando login en:", url);

    try {
      const response = await axios({
        method: "post",
        url,
        data: userData,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        withCredentials: true,
        timeout: 30000,
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        },
        maxRedirects: 0,
        proxy: false,
      });

      console.log("✅ Login exitoso");
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.userId);
      setMessage("Inicio de sesión exitoso");
      navigate("/dashboard");
    } catch (error) {
      console.error("❌ Error en login:", {
        message: error.message,
        code: error.code,
        response: error.response?.data,
        config: error.config,
        request: error.request,
      });

      if (error.code === "ERR_NETWORK") {
        setMessage(
          "Error de conexión. Por favor, verifica tu conexión a internet y que el servidor esté disponible."
        );
      } else if (error.response) {
        setMessage(error.response.data.message || "Error en el servidor");
      } else {
        setMessage(
          `Error al intentar conectar con el servidor: ${error.message}`
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-900 to-black py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-[400px] space-y-8 bg-white/10 backdrop-blur-lg p-8 rounded-2xl border border-gray-700/50">
        <div className="flex flex-col items-center">
          <h2 className="text-3xl font-extrabold bg-gradient-to-r from-emerald-400 to-cyan-400 bg-clip-text text-transparent">
            Iniciar Sesión
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4 ml-2 flex-col items-center">
            <input
              type="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              placeholder="Correo electrónico"
              required
              className="appearance-none relative block w-full max-w-[300px] px-4 py-3 border border-gray-700 bg-gray-800/50 placeholder-gray-400 text-white rounded-xl focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all duration-300"
            />
            <div className="relative w-full max-w-[300px]">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={userData.password}
                onChange={handleChange}
                placeholder="Contraseña"
                required
                className="appearance-none relative block w-full px-4 py-3 border border-gray-700 bg-gray-800/50 placeholder-gray-400 text-white rounded-xl focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all duration-300 pr-12"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-emerald-400 hover:text-emerald-300 transition-colors duration-200 bg-transparent border-0 p-0 m-0 cursor-pointer w-auto inline-flex"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center w-full">
            <button
              type="submit"
              disabled={isLoading}
              className="w-full max-w-[300px] flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-xl text-white bg-gradient-to-r from-emerald-500 to-cyan-500 hover:from-emerald-600 hover:to-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition-all duration-300 transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <div className="flex items-center gap-2">
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Cargando...</span>
                </div>
              ) : (
                "Iniciar Sesión"
              )}
            </button>
          </div>
        </form>

        {message && (
          <div
            className={`mt-4 p-4 rounded-xl text-center ${
              message.includes("exitoso")
                ? "bg-emerald-500/20 text-emerald-200"
                : "bg-red-500/20 text-red-200"
            }`}
          >
            {message}
          </div>
        )}

        <div className="space-y-2 text-center text-gray-300">
          <div>
            ¿No tienes una cuenta?{" "}
            <Link
              to="/register"
              className="font-medium text-emerald-400 hover:text-emerald-300 transition-colors duration-300"
            >
              Regístrate
            </Link>
          </div>
          <div>
            <Link
              to="/forgot-password"
              className="font-medium text-emerald-400 hover:text-emerald-300 transition-colors duration-300"
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
