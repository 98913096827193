import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Register from "./components/Register";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import Calculadora from "./components/Calculadora";
import { HelmetProvider } from "react-helmet-async";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import LegalNotice from "./components/LegalNotice";
import Homepage from "./components/Homepage";
import Settings from "./components/Settings";
import axios from "axios";
import { buildUrl } from "./utils/url";

function App() {
  const [activeSection, setActiveSection] = useState("resume");
  const [userData, setUserData] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const response = await axios.get(
          buildUrl(process.env.REACT_APP_API_URL, "auth/profile"),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    setUserData(null);
    window.location.href = "/login";
  };

  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Header
            userData={userData}
            handleLogout={handleLogout}
            setShowDeleteConfirm={setShowDeleteConfirm}
          />

          <main>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/calculator" element={<Calculadora />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route path="/legal" element={<LegalNotice />} />
              <Route
                path="/dashboard/settings"
                element={
                  <ProtectedRoute>
                    <Settings
                      activeSection={activeSection}
                      setActiveSection={setActiveSection}
                      handleLogout={handleLogout}
                      setShowDeleteConfirm={setShowDeleteConfirm}
                      userData={userData}
                    />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
