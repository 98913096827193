import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../assets/images/logo.png";
import { buildUrl } from "../utils/url";
import { FaChevronDown } from "react-icons/fa";

const Header = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteConfirmText, setDeleteConfirmText] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const isInDashboard = location.pathname === "/dashboard";

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAuthenticated(false);
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          buildUrl(process.env.REACT_APP_API_URL, "auth/profile"),
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            timeout: 30000,
          }
        );

        if (response.data) {
          setUserData(response.data);
          setIsAuthenticated(true);
        } else {
          throw new Error("No user data received");
        }
      } catch (error) {
        console.error("Auth check failed:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        setIsAuthenticated(false);

        if (location.pathname === "/dashboard") {
          navigate("/login");
        }
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [navigate, location.pathname]);

  // Cerrar el dropdown al hacer clic fuera
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    setIsAuthenticated(false);
    navigate("/login");
  };

  const handleDeleteAccount = async () => {
    if (deleteConfirmText !== "Eliminar cuenta") {
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        buildUrl(process.env.REACT_APP_API_URL, "auth/delete-account"),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al eliminar la cuenta:", error);
    } finally {
      setShowDeleteConfirm(false);
      setDeleteConfirmText("");
    }
  };

  if (isLoading) {
    return (
      <div className="w-full bg-[var(--bg-secondary)] border-b border-[var(--border-color)]">
        <nav
          className={`w-full px-4 py-2 ${isInDashboard ? "md:pl-[280px]" : ""}`}
        >
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center">
              <img
                src={logo}
                alt="Logo"
                className="h-12 md:h-16 object-contain"
              />
            </Link>
          </div>
        </nav>
      </div>
    );
  }

  return (
    <>
      <div className="w-full bg-[var(--bg-secondary)] border-b border-[var(--border-color)]">
        <nav
          className={`w-full px-4 py-2 ${isInDashboard ? "md:pl-[280px]" : ""}`}
        >
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center">
              <img
                src={logo}
                alt="Logo"
                className="h-12 md:h-16 object-contain transition-transform duration-300 hover:scale-110"
              />
            </Link>

            <ul className="flex items-center gap-6 list-none p-0 m-0">
              <li>
                <Link
                  to="/calculator"
                  className="text-[var(--text-primary)] no-underline transition-colors duration-300 hover:text-[var(--accent-color)]"
                >
                  Calculadora
                </Link>
              </li>
              <li className="relative" ref={dropdownRef}>
                {isAuthenticated && userData ? (
                  <div className="bg-transparent">
                    <div className="flex items-center gap-2">
                      <Link to="/dashboard" className="hidden md:block">
                        <div className="w-8 h-8 rounded-full border-2 border-[var(--accent-color)] overflow-hidden bg-white">
                          <img
                            src={`https://api.dicebear.com/7.x/initials/svg?seed=${userData?.username?.charAt(
                              0
                            )}`}
                            alt="Avatar"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      </Link>

                      <button
                        onClick={() => setShowDropdown(!showDropdown)}
                        className="flex items-center gap-2 bg-transparent md:hidden"
                      >
                        <div className="w-8 h-8 rounded-full border-2 border-[var(--accent-color)] overflow-hidden bg-white">
                          <img
                            src={`https://api.dicebear.com/7.x/initials/svg?seed=${userData?.username?.charAt(
                              0
                            )}`}
                            alt="Avatar"
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <FaChevronDown
                          className={`text-[var(--text-primary)] transition-transform duration-200 ${
                            showDropdown ? "rotate-180" : ""
                          }`}
                        />
                      </button>
                    </div>

                    {showDropdown && (
                      <div className="absolute right-0 mt-2 w-48 bg-[var(--bg-primary)] rounded-lg shadow-lg border border-[var(--border-color)] py-1 md:hidden z-50">
                        <Link
                          to="/dashboard"
                          className="block px-4 py-2 text-[var(--text-primary)] hover:bg-[var(--bg-hover)]"
                          onClick={() => setShowDropdown(false)}
                        >
                          Dashboard
                        </Link>
                        <Link
                          to="/dashboard/settings"
                          className="block px-4 py-2 text-[var(--text-primary)] hover:bg-[var(--bg-hover)]"
                          onClick={() => setShowDropdown(false)}
                        >
                          Ajustes
                        </Link>
                        <button
                          onClick={handleLogout}
                          className="w-full bg-transparent text-left px-4 py-2 text-[var(--text-primary)] hover:bg-[var(--bg-hover)]"
                        >
                          Cerrar sesión
                        </button>
                        <button
                          onClick={() => {
                            setShowDeleteConfirm(true);
                            setShowDropdown(false);
                          }}
                          className="w-full bg-transparent text-left px-4 py-2 text-red-500 hover:bg-[var(--bg-hover)]"
                        >
                          Eliminar cuenta
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <Link
                    to="/login"
                    className="px-3 py-1.5 border-2 border-[var(--accent-color)] rounded-full text-[var(--text-primary)] no-underline transition-all duration-300 bg-transparent hover:bg-[var(--accent-color)] hover:text-[var(--font-color)]"
                  >
                    Iniciar sesión
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </nav>
      </div>

      {/* Modal de confirmación */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-[var(--bg-primary)] rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-[var(--text-primary)] text-lg font-semibold mb-4">
              ¿Estás seguro?
            </h3>
            <p className="text-[var(--text-secondary)] mb-4">
              Esta acción eliminará permanentemente tu cuenta y todos tus datos.
              Para confirmar, escribe "Eliminar cuenta" en el campo de abajo.
            </p>
            <div className="space-y-4">
              <input
                type="text"
                value={deleteConfirmText}
                onChange={(e) => setDeleteConfirmText(e.target.value)}
                placeholder="Escribe 'Eliminar cuenta'"
                className="m-0 w-full px-4 py-2 rounded border border-[var(--border-color)] bg-[var(--bg-secondary)] text-[var(--text-primary)]"
              />
              <div className="flex flex-col md:flex-row gap-4">
                <button
                  onClick={handleDeleteAccount}
                  disabled={deleteConfirmText !== "Eliminar cuenta"}
                  className={`px-4 py-2 rounded text-white ${
                    deleteConfirmText === "Eliminar cuenta"
                      ? "bg-red-500 hover:bg-red-600"
                      : "bg-red-300 cursor-not-allowed"
                  } transition-colors w-full md:w-auto`}
                >
                  Eliminar cuenta
                </button>
                <button
                  onClick={() => {
                    setShowDeleteConfirm(false);
                    setDeleteConfirmText("");
                  }}
                  className="px-4 py-2 rounded bg-[var(--bg-secondary)] text-[var(--text-primary)] hover:bg-[var(--bg-hover)] transition-colors w-full md:w-auto"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
