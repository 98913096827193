/**
 * Formatea un número como moneda en euros
 * @param {number} amount - Cantidad a formatear
 * @returns {string} Cantidad formateada como moneda (ej: "1.234,56 €")
 */
export const formatCurrency = (amount) => {
  return new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
  }).format(amount);
};
