import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { format } from "date-fns";
import { es } from "date-fns/locale";

import {
  FaPencilAlt,
  FaPlus,
  FaTrash,
  FaSun,
  FaMoon,
  FaFilter,
  FaChartLine,
  FaProjectDiagram,
  FaBullseye,
} from "react-icons/fa";
// Importar los componentes necesarios de Chart.js
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import Chatbot from "./Chatbot";
import ExpenseSummary from "./ExpenseSummary";
import FinancialProjection from "./FinancialProjection";
import Patrimonio from "./Patrimonio";
import MonthlyResume from "./MonthlyResume";
import MonthlyIncomes from "./MonthlyIncomes";
import MonthlyExpenses from "./MonthlyExpenses";
import MonthlyInvestments from "./MonthlyInvestments";
import CompoundCalculator from "./CompoundCalculator";
import Sidebar from "./Sidebar";
import Goals from "./Goals";
import { buildUrl } from "../utils/url";

// Registrar los componentes de Chart.js..
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

function Dashboard() {
  const [userData, setUserData] = useState(null);
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [editingIncomeIndex, setEditingIncomeIndex] = useState(null);
  const [editingExpenseIndex, setEditingExpenseIndex] = useState(null);
  const [showNewIncomeForm, setShowNewIncomeForm] = useState(false);
  const [showNewExpenseForm, setShowNewExpenseForm] = useState(false);
  const [newIncomeName, setNewIncomeName] = useState("");
  const [newIncomeAmount, setNewIncomeAmount] = useState("");
  const [newExpenseName, setNewExpenseName] = useState("");
  const [newExpenseAmount, setNewExpenseAmount] = useState("");
  const [confirmationMessages, setConfirmationMessages] = useState({
    incomes: "",
    expenses: "",
    investments: "",
    goals: "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [investments, setInvestments] = useState([]);
  const [editingInvestmentIndex, setEditingInvestmentIndex] = useState(null);
  const [showNewInvestmentForm, setShowNewInvestmentForm] = useState(false);
  const [newInvestmentName, setNewInvestmentName] = useState("");
  const [newInvestmentAmount, setNewInvestmentAmount] = useState("");
  const [capitalInicial, setCapitalInicial] = useState(1000);
  const [aportacionMensual, setAportacionMensual] = useState(100);
  const [anos, setAnos] = useState(10);
  const [tasaInteres, setTasaInteres] = useState(8);
  const [resultado, setResultado] = useState(null);
  const [ganado, setGanado] = useState(null);
  const [dataChart, setDataChart] = useState([]);
  const [considerarInflacion, setConsiderarInflacion] = useState(false);
  const [tasaInflacion, setTasaInflacion] = useState(2); // Inflación media histórica aproximada
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [showCategoryFilter, setShowCategoryFilter] = useState(false);
  const [showExpenseSummary, setShowExpenseSummary] = useState(false);
  const [showFinancialProjection, setShowFinancialProjection] = useState(false);
  const [patrimony, setPatrimony] = useState([]);
  const [showPatrimonySection, setShowPatrimonySection] = useState(true);
  const [activeSection, setActiveSection] = useState("resume");
  const [goals, setGoals] = useState([]);
  const [deleteConfirmText, setDeleteConfirmText] = useState("");

  const categories = [
    "Alimentación",
    "Transporte",
    "Vivienda",
    "Servicios",
    "Entretenimiento",
    "Salud",
    "Educación",
    "Ropa",
    "Tecnología",
    "Otros",
  ];

  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        handleLogout();
        return;
      }

      try {
        const response = await axios.get(
          buildUrl(process.env.REACT_APP_API_URL, "auth/profile"),
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            timeout: 30000,
          }
        );

        if (response.data) {
          setUserData(response.data);
          setIncomes(response.data.incomes || []);
          setExpenses(
            Array.isArray(response.data.expenses) ? response.data.expenses : []
          );
          setInvestments(
            Array.isArray(response.data.investments)
              ? response.data.investments
              : []
          );
          setPatrimony(response.data.patrimony || []);
          setGoals(response.data.goals || []);
        } else {
          throw new Error("No se recibieron datos del usuario");
        }
      } catch (err) {
        if (err.code === "ECONNABORTED") {
          setError("Tiempo de espera agotado. Por favor, intenta de nuevo.");
        } else if (err.response?.status === 401) {
          if (err.response?.data?.expired) {
            setError(
              "Tu sesión ha expirado. Por favor, inicia sesión nuevamente."
            );
            setTimeout(() => {
              handleLogout();
            }, 2000);
          } else {
            handleLogout();
          }
        } else {
          setError(
            err.response?.data?.message ||
              "Error al cargar los datos del usuario"
          );
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [handleLogout]);

  const saveFinancialData = async (
    updatedIncomes = incomes,
    updatedExpenses = expenses,
    updatedInvestments = investments
  ) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios({
        method: "post",
        url: buildUrl(process.env.REACT_APP_API_URL, "auth/update-financial"),
        data: {
          incomes: updatedIncomes,
          expenses: updatedExpenses,
          investments: updatedInvestments,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        timeout: 30000,
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        },
        maxRedirects: 0,
        proxy: false,
      });

      return response.data;
    } catch (error) {
      console.error("Error en saveFinancialData:", {
        message: error.message,
        code: error.code,
        response: error.response?.data,
        config: error.config,
      });
      throw error;
    }
  };

  const handleIncomeEdit = (index) => {
    setEditingIncomeIndex(index);
  };

  const handleIncomeUpdate = (index, field, value) => {
    if (field === "amount") {
      // Validar que el valor es numérico y positivo
      const numValue = parseFloat(value);
      if (isNaN(numValue) || numValue < 0) return;
    }

    setIncomes((prevIncomes) => {
      const newIncomes = [...prevIncomes];
      newIncomes[index] = {
        ...newIncomes[index],
        [field]: field === "amount" ? parseFloat(value) : value,
      };
      return newIncomes;
    });
  };

  const handleIncomeSave = async (index) => {
    setEditingIncomeIndex(null);
    try {
      const response = await saveFinancialData();
      if (response.success) {
        showConfirmationMessage("Ingreso actualizado con éxito", "incomes");
      } else {
        setError("Error al actualizar el ingreso");
      }
    } catch (err) {
      console.error("Error al actualizar el ingreso:", err);
      setError("Error al actualizar el ingreso");
    }
  };

  const handleIncomeDelete = async (index) => {
    try {
      const updatedIncomes = incomes.filter((_, i) => i !== index);
      const response = await saveFinancialData(updatedIncomes);

      if (response.success) {
        setIncomes(response.incomes);
        showConfirmationMessage("Ingreso eliminado con éxito", "incomes");
      } else {
        setError("Error al eliminar el ingreso");
      }
    } catch (err) {
      console.error("Error al eliminar el ingreso:", err);
      setError("Error al eliminar el ingreso");
    }
  };

  const handleExpenseEdit = (index) => {
    setEditingExpenseIndex(index);
  };

  const handleExpenseUpdate = (index, field, value) => {
    if (field === "amount") {
      // Validar que el valor es numérico y positivo
      const numValue = parseFloat(value);
      if (isNaN(numValue) || numValue < 0) return;
    }

    setExpenses((prevExpenses) => {
      const newExpenses = [...prevExpenses];
      newExpenses[index] = {
        ...newExpenses[index],
        [field]: field === "amount" ? parseFloat(value) : value,
      };
      return newExpenses;
    });
  };

  const handleExpenseSave = async (index) => {
    setEditingExpenseIndex(null);
    try {
      const response = await saveFinancialData();
      if (response.success) {
        showConfirmationMessage("Gasto actualizado con éxito", "expenses");
      } else {
        setError("Error al actualizar el gasto");
      }
    } catch (err) {
      console.error("Error al actualizar el gasto:", err);
      setError("Error al actualizar el gasto");
    }
  };

  const handleExpenseDelete = async (index) => {
    try {
      const updatedExpenses = expenses.filter((_, i) => i !== index);
      const response = await saveFinancialData(undefined, updatedExpenses);

      if (response.success) {
        setExpenses(response.expenses);
        showConfirmationMessage("Gasto eliminado con éxito", "expenses");
      } else {
        setError("Error al eliminar el gasto");
      }
    } catch (err) {
      console.error("Error al eliminar el gasto:", err);
      setError("Error al eliminar el gasto");
    }
  };

  const toggleNewIncomeForm = () => {
    setShowNewIncomeForm(!showNewIncomeForm);
    setNewIncomeName("");
    setNewIncomeAmount("");
  };

  const toggleNewExpenseForm = () => {
    setShowNewExpenseForm((prev) => !prev);
  };

  const showConfirmationMessage = (message, section) => {
    setConfirmationMessages((prev) => ({
      ...prev,
      [section]: message,
    }));
    setTimeout(() => {
      setConfirmationMessages((prev) => ({
        ...prev,
        [section]: "",
      }));
    }, 3000);
  };

  const addIncome = async () => {
    if (newIncomeName && newIncomeAmount) {
      const newIncome = {
        name: newIncomeName,
        amount: parseFloat(newIncomeAmount),
      };
      const updatedIncomes = [...incomes, newIncome];

      try {
        const response = await saveFinancialData(updatedIncomes);
        if (response.success) {
          setIncomes(response.incomes);
          setNewIncomeName("");
          setNewIncomeAmount("");
          setShowNewIncomeForm(false);
          showConfirmationMessage("Nuevo ingreso añadido con éxito", "incomes");
        } else {
          setError("Error al guardar el nuevo ingreso");
        }
      } catch (err) {
        console.error("Error al guardar el nuevo ingreso:", err);
        setError("Error al guardar el nuevo ingreso");
      }
    }
  };

  const addExpense = async () => {
    if (newExpenseName && newExpenseAmount) {
      try {
        const categoryResponse = await axios({
          method: "POST",
          url: buildUrl(process.env.REACT_APP_API_URL, "api/categorize"),
          data: { expenseName: newExpenseName },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          timeout: 30000,
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
          maxRedirects: 0,
          proxy: false,
        });

        if (!categoryResponse.data.success) {
          throw new Error("Error en categorización");
        }

        const newExpense = {
          name: newExpenseName,
          amount: parseFloat(newExpenseAmount),
          category: categoryResponse.data.category || "Otros",
        };

        const updatedExpenses = [...expenses, newExpense];
        const response = await saveFinancialData(undefined, updatedExpenses);

        if (response.success) {
          setExpenses(response.expenses);
          setNewExpenseName("");
          setNewExpenseAmount("");
          setShowNewExpenseForm(false);
          showConfirmationMessage("Gasto añadido con éxito", "expenses");
        } else {
          throw new Error(response.message || "Error al guardar el gasto");
        }
      } catch (error) {
        console.error("Error al añadir gasto:", {
          message: error.message,
          code: error.code,
          response: error.response?.data,
          config: error.config,
        });
        setError(error.message || "Error al añadir el gasto");
      }
    }
  };

  const handleInvestmentEdit = (index) => {
    setEditingInvestmentIndex(index);
  };

  const handleInvestmentUpdate = (index, field, value) => {
    const updatedInvestments = [...investments];
    updatedInvestments[index][field] = value;
    setInvestments(updatedInvestments);
  };

  const handleInvestmentSave = async (index) => {
    setEditingInvestmentIndex(null);
    try {
      const response = await saveFinancialData(
        undefined,
        undefined,
        investments
      );
      if (response.success) {
        showConfirmationMessage(
          "Inversión actualizada con éxito",
          "investments"
        );
      } else {
        setError("Error al actualizar la inversión");
      }
    } catch (err) {
      console.error("Error al actualizar la inversión:", err);
      setError("Error al actualizar la inversión");
    }
  };

  const handleInvestmentDelete = async (index) => {
    try {
      const updatedInvestments = investments.filter((_, i) => i !== index);
      const response = await saveFinancialData(
        undefined,
        undefined,
        updatedInvestments
      );

      if (response.success) {
        setInvestments(response.investments);
        showConfirmationMessage("Inversión eliminada con xito", "investments");
      } else {
        setError("Error al eliminar la inversión");
      }
    } catch (err) {
      console.error("Error al eliminar la inversión:", err);
      setError("Error al eliminar la inversión");
    }
  };

  const toggleNewInvestmentForm = () => {
    setShowNewInvestmentForm(!showNewInvestmentForm);
    setNewInvestmentName("");
    setNewInvestmentAmount("");
  };

  const addInvestment = async () => {
    if (newInvestmentName && newInvestmentAmount) {
      const newInvestment = {
        name: newInvestmentName,
        amount: parseFloat(newInvestmentAmount),
      };
      const updatedInvestments = [...investments, newInvestment];

      try {
        console.log("💾 Guardando nueva inversión:", newInvestment);
        const response = await saveFinancialData(
          undefined,
          undefined,
          updatedInvestments
        );

        if (response.success) {
          setInvestments(response.investments);
          setNewInvestmentName("");
          setNewInvestmentAmount("");
          setShowNewInvestmentForm(false);
          showConfirmationMessage(
            "Nueva inversión añadida con éxito",
            "investments"
          );
        } else {
          setError("Error al guardar la nueva inversión");
        }
      } catch (err) {
        console.error("❌ Error al guardar la nueva inversión:", err);
        setError("Error al guardar la nueva inversión");
      }
    } else {
      setError("Por favor, completa todos los campos de la inversión");
    }
  };

  // Función para generar un color aleatorio en formato hexadecimal
  const generateRandomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  // Función para generar un array de colores
  const generateColorArray = (length) => {
    const baseColors = [
      "#eab8ff",
      "#d17aff",
      "#a857ff",
      "#6d2eff",
      "#390b7f",
      "#FF9F40",
    ];

    if (length <= baseColors.length) {
      return baseColors.slice(0, length);
    }

    const additionalColors = Array(length - baseColors.length)
      .fill()
      .map(() => generateRandomColor());
    return [...baseColors, ...additionalColors];
  };

  // Función para preparar los datos del gráfico
  const prepareChartData = (expenses = incomes) => {
    const labels = expenses.map((expense) => expense.name);
    const data = expenses.map((expense) => expense.amount);
    const colors = generateColorArray(data.length);

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: colors,
          hoverBackgroundColor: colors.map((color) => {
            // Crear una versión más clara del color para el hover
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            return `rgba(${r}, ${g}, ${b}, 0.8)`;
          }),
          borderWidth: 0.75,
        },
      ],
    };
  };

  // Opciones del gráfico
  const chartOptions = {
    plugins: {
      legend: {
        display: false, // Oculta la leyenda
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce(
              (acc, data) => acc + data,
              0
            );
            const percentage = ((value / total) * 100).toFixed(2);
            return `${label}: ${value}€ (${percentage}%)`;
          },
        },
      },
    },
  };

  // Modifica la función prepareBarChartData para incluir inversiones
  const prepareBarChartData = () => {
    const totalIncome = incomes.reduce((acc, income) => acc + income.amount, 0);
    const totalExpense = expenses.reduce(
      (acc, expense) => acc + expense.amount,
      0
    );
    const totalInvestment = investments.reduce(
      (acc, investment) => acc + investment.amount,
      0
    );

    return {
      labels: ["Ingresos", "Gastos", "Inversiones"],
      datasets: [
        {
          label: "Cantidad en €",
          data: [totalIncome, totalExpense, totalInvestment],
          backgroundColor: ["#6dd08c", "#f084a1", "#a857ff"], // Verde, Rojo, Morado
          borderColor: ["white", "white", "white"],
          borderWidth: 0.75,
        },
      ],
    };
  };

  // Función para obtener colores con buen contraste
  const getChartColors = () => {
    return {
      text: isDarkMode ? "#ffffff" : "#000000",
      grid: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
      tooltip: {
        background: isDarkMode
          ? "rgba(0, 0, 0, 0.8)"
          : "rgba(255, 255, 255, 0.8)",
        text: isDarkMode ? "#ffffff" : "#000000",
        border: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
      },
    };
  };

  // Actualiza las opciones del gráfico de barras del resumen
  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: getChartColors().tooltip.background,
        titleColor: getChartColors().tooltip.text,
        bodyColor: getChartColors().tooltip.text,
        borderColor: getChartColors().tooltip.border,
        borderWidth: 1,
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.formattedValue}€`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + "€";
          },
          color: getChartColors().text,
        },
        grid: {
          color: getChartColors().grid,
        },
      },
      x: {
        ticks: {
          color: getChartColors().text,
        },
        grid: {
          color: getChartColors().grid,
        },
      },
    },
  };

  // Actualiza las opciones del gráfico de la calculadora
  const calculatorChartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: getChartColors().text,
        },
        grid: {
          color: getChartColors().grid,
        },
      },
      x: {
        ticks: {
          color: getChartColors().text,
        },
        grid: {
          color: getChartColors().grid,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: getChartColors().text,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: getChartColors().tooltip.background,
        titleColor: getChartColors().tooltip.text,
        bodyColor: getChartColors().tooltip.text,
        borderColor: getChartColors().tooltip.border,
        borderWidth: 1,
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${parseInt(
              context.raw
            ).toLocaleString()}€`;
          },
        },
      },
    },
  };

  const handleDeleteAccount = async () => {
    if (deleteConfirmText !== "Eliminar cuenta") {
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        buildUrl(process.env.REACT_APP_API_URL, "auth/delete-account"),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al eliminar la cuenta:", error);
    } finally {
      setShowDeleteConfirm(false);
      setDeleteConfirmText("");
    }
  };

  const calcularInteresCompuesto = useCallback(() => {
    let dineroActual = parseFloat(capitalInicial);
    const anios = parseInt(anos);
    const tasa = parseFloat(tasaInteres);
    const adicionMensual = parseFloat(aportacionMensual);
    const data = [];
    const dataReal = []; // Valor ajustado por inflación

    for (let i = 0; i < anios * 12; i++) {
      dineroActual += adicionMensual;
      dineroActual *= 1 + tasa / 100 / 12;

      if (i % 12 === 11) {
        data.push(dineroActual.toFixed(2));

        if (considerarInflacion) {
          // Calculamos el valor real ajustado por inflación
          const valorReal =
            dineroActual /
            Math.pow(1 + tasaInflacion / 100, Math.floor(i / 12) + 1);
          dataReal.push(valorReal.toFixed(2));
        }
      }
    }

    let totalGanado;
    if (considerarInflacion) {
      // Ajustamos las ganancias considerando la inflación
      const valorFinalReal = parseFloat(dataReal[dataReal.length - 1]);
      const inversionTotalNominal =
        parseFloat(capitalInicial) + adicionMensual * anios * 12;
      const inversionTotalReal =
        inversionTotalNominal / Math.pow(1 + tasaInflacion / 100, anios);
      totalGanado = valorFinalReal - inversionTotalReal;
      setResultado(valorFinalReal.toFixed(2));
    } else {
      totalGanado =
        dineroActual -
        (parseFloat(capitalInicial) + adicionMensual * anios * 12);
      setResultado(dineroActual.toFixed(2));
    }

    setGanado(totalGanado.toFixed(2));
    setDataChart({
      nominal: data,
      real: considerarInflacion ? dataReal : null,
    });
  }, [
    capitalInicial,
    aportacionMensual,
    anos,
    tasaInteres,
    considerarInflacion,
    tasaInflacion,
  ]);

  useEffect(() => {
    calcularInteresCompuesto();
  }, [calcularInteresCompuesto]);

  const handleIncrement = (setter, value, step) => () => {
    setter((prevValue) => {
      const newValue = parseFloat(prevValue) + step;
      return newValue.toString();
    });
  };

  const handleDecrement = (setter, value, step) => () => {
    setter((prevValue) => {
      const newValue = Math.max(0, parseFloat(prevValue) - step);
      return newValue.toString();
    });
  };

  const toggleTheme = () => {
    const root = document.documentElement;
    const newMode = !isDarkMode;

    if (newMode) {
      root.classList.remove("light-theme");
    } else {
      root.classList.add("light-theme");
    }

    setIsDarkMode(newMode);
    localStorage.setItem("darkMode", newMode);
  };

  useEffect(() => {
    const savedMode = localStorage.getItem("darkMode");
    if (savedMode !== null) {
      const isDark = savedMode === "true";
      setIsDarkMode(isDark);
      if (!isDark) {
        document.documentElement.classList.add("light-theme");
      }
    }
  }, []);

  // Función para filtrar gastos por categoría
  const filteredExpenses =
    selectedCategory === "all"
      ? expenses
      : expenses.filter((expense) => expense.category === selectedCategory);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const popup = document.querySelector(".category-filter-popup");
      const filterIcon = document.querySelector(
        ".expenses-actions svg:last-child"
      ); // El icono de FaFilter

      if (
        showCategoryFilter &&
        popup &&
        !popup.contains(event.target) &&
        !filterIcon.contains(event.target)
      ) {
        setShowCategoryFilter(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showCategoryFilter]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setShowCategoryFilter(false); // Cerrar el popup después de seleccionar
  };

  // Funciones para manejar el patrimonio
  const handleAddPatrimony = async (newPatrimony) => {
    try {
      const response = await axios.post(
        buildUrl(process.env.REACT_APP_API_URL, "auth/update-patrimony"),
        {
          action: "add",
          patrimony: newPatrimony,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        setPatrimony(response.data.patrimony);
      } else {
        throw new Error("Error al añadir el activo");
      }
    } catch (error) {
      setError("Error al añadir el activo");
      throw error; // Propagar el error para que Patrimonio.js pueda manejarlo
    }
  };

  const handleUpdatePatrimony = async (index, updatedPatrimony) => {
    try {
      const response = await axios.post(
        buildUrl(process.env.REACT_APP_API_URL, "auth/update-patrimony"),
        {
          action: "update",
          index,
          patrimony: updatedPatrimony,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        setPatrimony(response.data.patrimony);
      } else {
        throw new Error("Error al actualizar el activo");
      }
    } catch (error) {
      setError("Error al actualizar el activo");
      throw error; // Propagar el error para que Patrimonio.js pueda manejarlo
    }
  };

  const handleDeletePatrimony = async (index) => {
    try {
      const response = await axios.post(
        buildUrl(process.env.REACT_APP_API_URL, "auth/update-patrimony"),
        {
          action: "delete",
          index,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        setPatrimony(response.data.patrimony);
      }
    } catch (error) {
      setError("Error al eliminar el activo");
    }
  };

  const currentDate = format(new Date(), "EEEE, d 'de' MMMM 'de' yyyy", {
    locale: es,
  });

  // Capitalizar primera letra
  const formattedDate =
    currentDate.charAt(0).toUpperCase() + currentDate.slice(1);

  const handleAddGoal = async (newGoal) => {
    try {
      const response = await axios({
        method: "post",
        url: buildUrl(process.env.REACT_APP_API_URL, "auth/update-goals"),
        data: {
          action: "add",
          goal: newGoal,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        timeout: 30000,
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        },
        maxRedirects: 0,
        proxy: false,
      });

      if (response.data.success) {
        setGoals(response.data.goals);
        showConfirmationMessage("Objetivo añadido correctamente", "goals");
      }
    } catch (error) {
      console.error("Error al añadir el objetivo:", {
        message: error.message,
        code: error.code,
        response: error.response?.data,
        config: error.config,
      });
      setError("Error al añadir el objetivo");
    }
  };

  const handleUpdateGoal = async (index, updatedGoal) => {
    try {
      const response = await axios({
        method: "post",
        url: buildUrl(process.env.REACT_APP_API_URL, "auth/update-goals"),
        data: {
          action: "update",
          index,
          goal: updatedGoal,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        timeout: 30000,
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        },
        maxRedirects: 0,
        proxy: false,
      });

      if (response.data.success) {
        setGoals(response.data.goals);
        showConfirmationMessage("Objetivo actualizado correctamente", "goals");
      }
    } catch (error) {
      console.error("Error al actualizar el objetivo:", {
        message: error.message,
        code: error.code,
        response: error.response?.data,
        config: error.config,
      });
      setError("Error al actualizar el objetivo");
    }
  };

  const handleDeleteGoal = async (index) => {
    try {
      const response = await axios({
        method: "post",
        url: buildUrl(process.env.REACT_APP_API_URL, "auth/update-goals"),
        data: {
          action: "delete",
          index,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        timeout: 30000,
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        },
        maxRedirects: 0,
        proxy: false,
      });

      if (response.data.success) {
        setGoals(response.data.goals);
        showConfirmationMessage("Objetivo eliminado correctamente", "goals");
      }
    } catch (error) {
      console.error("Error al eliminar el objetivo:", {
        message: error.message,
        code: error.code,
        response: error.response?.data,
        config: error.config,
      });
      setError("Error al eliminar el objetivo");
    }
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
    if (section === "settings") {
      navigate("/dashboard/settings");
    }
  };

  return (
    <div className="flex min-h-screen bg-[var(--bg-primary)] overflow-hidden">
      <Sidebar
        activeSection={activeSection}
        setActiveSection={handleSectionChange}
        handleLogout={handleLogout}
        setShowDeleteConfirm={setShowDeleteConfirm}
        userData={userData}
      />
      <div className="flex-1 px-4 py-6 md:px-8 md:py-8 md:pl-[280px] pb-24 md:pb-12 transition-all duration-300 overflow-x-hidden w-full max-w-[100vw]">
        {activeSection === "resume" && (
          <div className="w-full max-w-full overflow-hidden">
            <span className="block text-[var(--text-secondary)] text-sm mb-2">
              {formattedDate}
            </span>
            <h1 className="text-gradient text-xl md:text-2xl font-semibold mb-6">
              Bienvenido a tu Dashboard, {userData?.username}
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
              <MonthlyResume
                barChartData={prepareBarChartData()}
                barChartOptions={barChartOptions}
                incomes={incomes}
                expenses={expenses}
                investments={investments}
                patrimony={patrimony}
                setActiveSection={setActiveSection}
              />
            </div>
            <div className="mt-6">
              <FinancialProjection
                currentIncomes={incomes.reduce(
                  (acc, income) => acc + income.amount,
                  0
                )}
                currentExpenses={expenses.reduce(
                  (acc, expense) => acc + expense.amount,
                  0
                )}
                currentInvestments={investments.reduce(
                  (acc, investment) => acc + investment.amount,
                  0
                )}
                currentPatrimony={patrimony.reduce(
                  (acc, item) => acc + item.amount,
                  0
                )}
              />
            </div>
          </div>
        )}

        {isLoading ? (
          <div className="text-center py-8 text-[var(--text-secondary)]">
            Loading...
          </div>
        ) : userData ? (
          <div className="grid gap-6 md:gap-8 w-full max-w-full overflow-hidden">
            {activeSection === "incomes" && (
              <MonthlyIncomes
                incomes={incomes}
                editingIncomeIndex={editingIncomeIndex}
                handleIncomeEdit={handleIncomeEdit}
                handleIncomeUpdate={handleIncomeUpdate}
                handleIncomeSave={handleIncomeSave}
                handleIncomeDelete={handleIncomeDelete}
                showNewIncomeForm={showNewIncomeForm}
                toggleNewIncomeForm={toggleNewIncomeForm}
                newIncomeName={newIncomeName}
                setNewIncomeName={setNewIncomeName}
                newIncomeAmount={newIncomeAmount}
                setNewIncomeAmount={setNewIncomeAmount}
                addIncome={addIncome}
                prepareChartData={prepareChartData}
                chartOptions={chartOptions}
                setEditingIncomeIndex={setEditingIncomeIndex}
              />
            )}
            {activeSection === "expenses" && (
              <MonthlyExpenses
                expenses={expenses}
                editingExpenseIndex={editingExpenseIndex}
                handleExpenseEdit={handleExpenseEdit}
                handleExpenseUpdate={handleExpenseUpdate}
                handleExpenseSave={handleExpenseSave}
                handleExpenseDelete={handleExpenseDelete}
                showNewExpenseForm={showNewExpenseForm}
                toggleNewExpenseForm={toggleNewExpenseForm}
                newExpenseName={newExpenseName}
                setNewExpenseName={setNewExpenseName}
                newExpenseAmount={newExpenseAmount}
                setNewExpenseAmount={setNewExpenseAmount}
                addExpense={addExpense}
                showExpenseSummary={showExpenseSummary}
                setShowExpenseSummary={setShowExpenseSummary}
                showCategoryFilter={showCategoryFilter}
                setShowCategoryFilter={setShowCategoryFilter}
                selectedCategory={selectedCategory}
                handleCategorySelect={handleCategorySelect}
                categories={categories}
                filteredExpenses={filteredExpenses}
                ExpenseSummary={ExpenseSummary}
                setEditingExpenseIndex={setEditingExpenseIndex}
                prepareChartData={prepareChartData}
                chartOptions={chartOptions}
              />
            )}
            {activeSection === "investments" && (
              <MonthlyInvestments
                investments={investments}
                editingInvestmentIndex={editingInvestmentIndex}
                handleInvestmentEdit={handleInvestmentEdit}
                handleInvestmentUpdate={handleInvestmentUpdate}
                handleInvestmentSave={handleInvestmentSave}
                handleInvestmentDelete={handleInvestmentDelete}
                showNewInvestmentForm={showNewInvestmentForm}
                toggleNewInvestmentForm={toggleNewInvestmentForm}
                newInvestmentName={newInvestmentName}
                setNewInvestmentName={setNewInvestmentName}
                newInvestmentAmount={newInvestmentAmount}
                setNewInvestmentAmount={setNewInvestmentAmount}
                addInvestment={addInvestment}
                prepareChartData={prepareChartData}
                chartOptions={chartOptions}
                setEditingInvestmentIndex={setEditingInvestmentIndex}
              />
            )}
            {activeSection === "calculator" && (
              <CompoundCalculator
                capitalInicial={capitalInicial}
                setCapitalInicial={setCapitalInicial}
                aportacionMensual={aportacionMensual}
                setAportacionMensual={setAportacionMensual}
                anos={anos}
                setAnos={setAnos}
                tasaInteres={tasaInteres}
                setTasaInteres={setTasaInteres}
                considerarInflacion={considerarInflacion}
                setConsiderarInflacion={setConsiderarInflacion}
                tasaInflacion={tasaInflacion}
                setTasaInflacion={setTasaInflacion}
                resultado={resultado}
                ganado={ganado}
                dataChart={dataChart}
                calculatorChartOptions={calculatorChartOptions}
                handleIncrement={handleIncrement}
                handleDecrement={handleDecrement}
              />
            )}
            {activeSection === "assistant" && <Chatbot />}
            {activeSection === "goals" && (
              <Goals
                goals={goals}
                onAddGoal={handleAddGoal}
                onUpdateGoal={handleUpdateGoal}
                onDeleteGoal={handleDeleteGoal}
                currentIncomes={incomes.reduce(
                  (acc, income) => acc + income.amount,
                  0
                )}
                currentExpenses={expenses.reduce(
                  (acc, expense) => acc + expense.amount,
                  0
                )}
                currentInvestments={investments.reduce(
                  (acc, inv) => acc + inv.amount,
                  0
                )}
              />
            )}
            {activeSection === "patrimony" && (
              <div className="">
                <Patrimonio
                  patrimony={patrimony}
                  onAddPatrimony={handleAddPatrimony}
                  onUpdatePatrimony={handleUpdatePatrimony}
                  onDeletePatrimony={handleDeletePatrimony}
                />
              </div>
            )}
          </div>
        ) : null}

        {error && (
          <div className="bg-red-100 text-red-700 p-[var(--spacing-base)] rounded-[var(--border-radius)] mt-4">
            {error}
          </div>
        )}

        {showDeleteConfirm && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <div className="bg-[var(--bg-primary)] rounded-lg p-6 max-w-md w-full mx-4">
              <h3 className="text-[var(--text-primary)] text-lg font-semibold mb-4">
                ¿Estás seguro?
              </h3>
              <p className="text-[var(--text-secondary)] mb-4">
                Esta acción eliminará permanentemente tu cuenta y todos tus
                datos. Para confirmar, escribe "Eliminar cuenta" en el campo de
                abajo.
              </p>
              <div className="space-y-4">
                <input
                  type="text"
                  value={deleteConfirmText}
                  onChange={(e) => setDeleteConfirmText(e.target.value)}
                  placeholder="Escribe 'Eliminar cuenta'"
                  className="m-0 w-full px-4 py-2 rounded border border-[var(--border-color)] bg-[var(--bg-secondary)] text-[var(--text-primary)]"
                />
                <div className="flex flex-col md:flex-row gap-4">
                  <button
                    onClick={handleDeleteAccount}
                    disabled={deleteConfirmText !== "Eliminar cuenta"}
                    className={`px-4 py-2 rounded text-white ${
                      deleteConfirmText === "Eliminar cuenta"
                        ? "bg-red-500 hover:bg-red-600"
                        : "bg-red-300 cursor-not-allowed"
                    } transition-colors w-full md:w-auto`}
                  >
                    Eliminar cuenta
                  </button>
                  <button
                    onClick={() => {
                      setShowDeleteConfirm(false);
                      setDeleteConfirmText("");
                    }}
                    className="px-4 py-2 rounded bg-[var(--bg-secondary)] text-[var(--text-primary)] hover:bg-[var(--bg-hover)] transition-colors w-full md:w-auto"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
