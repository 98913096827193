import React from "react";
import { Bar } from "react-chartjs-2";

const ExpenseSummary = ({ expenses }) => {
  // Agrupar gastos por categoría
  const expensesByCategory = expenses.reduce((acc, expense) => {
    if (!acc[expense.category]) {
      acc[expense.category] = 0;
    }
    acc[expense.category] += expense.amount;
    return acc;
  }, {});

  // Preparar datos para el gráfico
  const chartData = {
    labels: Object.keys(expensesByCategory),
    datasets: [
      {
        data: Object.values(expensesByCategory),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
        ],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Distribución de Gastos por Categoría",
        color: "#f3f4f6",
        font: {
          size: 14,
          weight: 500,
        },
        padding: 20,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            const total = context.dataset.data.reduce(
              (acc, curr) => acc + curr,
              0
            );
            const percentage = ((value / total) * 100).toFixed(1);
            return `${value.toFixed(2)}€ (${percentage}%)`;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#f3f4f6",
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: "#f3f4f6",
          callback: function (value) {
            return value + "€";
          },
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
          drawBorder: false,
        },
      },
    },
  };

  return (
    <div>
      <div className="mb-8">
        <Bar data={chartData} options={chartOptions} />
      </div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="p-3 text-left bg-[var(--bg-primary)] font-semibold border-b border-[var(--border-color)]">
                Categoría
              </th>
              <th className="p-3 text-left bg-[var(--bg-primary)] font-semibold border-b border-[var(--border-color)]">
                Total
              </th>
              <th className="p-3 text-left bg-[var(--bg-primary)] font-semibold border-b border-[var(--border-color)]">
                % del Total
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(expensesByCategory).map(([category, amount]) => {
              const percentage = (
                (amount / expenses.reduce((acc, exp) => acc + exp.amount, 0)) *
                100
              ).toFixed(1);
              return (
                <tr
                  key={category}
                  className="hover:bg-[var(--bg-hover)] transition-colors duration-200"
                >
                  <td className="p-3 border-b border-[var(--border-color)]">
                    {category}
                  </td>
                  <td className="p-3 border-b border-[var(--border-color)]">
                    {amount.toFixed(2)}€
                  </td>
                  <td className="p-3 border-b border-[var(--border-color)]">
                    {percentage}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExpenseSummary;
