import React, { useState, useEffect } from "react";
import {
  FaChartPie,
  FaMoneyBillWave,
  FaChartLine,
  FaCalculator,
  FaRobot,
  FaBars,
  FaSignOutAlt,
  FaTrash,
  FaArrowLeft,
  FaArrowRight,
  FaCircle,
  FaBullseye,
  FaLandmark,
  FaCog,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Sidebar = ({
  activeSection,
  setActiveSection,
  handleLogout,
  setShowDeleteConfirm,
  userData,
}) => {
  const [isOnline, setIsOnline] = useState(true);
  const [lastActivity, setLastActivity] = useState(Date.now());

  const toggleStatus = () => {
    setIsOnline(!isOnline);
    setLastActivity(Date.now());
  };

  useEffect(() => {
    const handleMouseMove = () => {
      setLastActivity(Date.now());
      setIsOnline(true);
    };

    window.addEventListener("mousemove", handleMouseMove);

    const checkActivity = setInterval(() => {
      const now = Date.now();
      if (now - lastActivity > 120000) {
        setIsOnline(false);
      }
    }, 1000);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      clearInterval(checkActivity);
    };
  }, [lastActivity]);

  const menuItems = [
    {
      id: "resume",
      label: "Resumen",
      icon: <FaChartPie className="text-[var(--icon-color)]" />,
    },
    {
      id: "incomes",
      label: "Ingresos",
      icon: <FaMoneyBillWave className="text-[var(--icon-color)]" />,
    },
    {
      id: "expenses",
      label: "Gastos",
      icon: <FaChartLine className="text-[var(--icon-color)]" />,
    },
    {
      id: "investments",
      label: "Inversiones",
      icon: <FaMoneyBillWave className="text-[var(--icon-color)]" />,
    },
    {
      id: "patrimony",
      label: "Patrimonio",
      icon: <FaLandmark className="text-[var(--icon-color)]" />,
    },
    {
      id: "goals",
      label: "Objetivos",
      icon: <FaBullseye className="text-[var(--icon-color)]" />,
    },
    {
      id: "calculator",
      label: "Calculadora",
      icon: <FaCalculator className="text-[var(--icon-color)]" />,
    },
    {
      id: "assistant",
      label: "AIsistente",
      icon: <FaRobot className="text-[var(--icon-color)]" />,
    },
  ];

  return (
    <>
      {/* Sidebar para desktop */}
      <div className="hidden md:flex w-[250px] h-screen bg-[var(--bg-secondary)] border-r border-[var(--border-color)] flex-col fixed left-0 top-0">
        <div className="m-4 flex items-center gap-3 border border-[var(--border-color)] rounded-[10px] p-3">
          <div className="flex items-center gap-3 flex-1">
            <div className="flex-shrink-0 w-8 h-8 rounded-full overflow-hidden border-2 border-[var(--accent-color)] bg-[var(--background-color)]">
              <img
                src={`https://api.dicebear.com/7.x/initials/svg?seed=${userData?.username?.charAt(
                  0
                )}&backgroundColor=ffffff&textColor=000000`}
                alt="Avatar"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex flex-col">
              <span className="text-[var(--text-primary)] font-medium text-sm">
                {userData?.username}
              </span>
              <div
                className="flex items-center gap-1"
                onClick={toggleStatus}
                style={{ cursor: "pointer" }}
              >
                <FaCircle
                  className={
                    isOnline
                      ? "text-green-500 w-2 h-2"
                      : "text-orange-500 w-2 h-2"
                  }
                />
                <span className="text-[var(--text-secondary)] text-xs">
                  {isOnline ? "Online" : "Ausente"}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col p-4 gap-1">
          {menuItems.map((item) => (
            <button
              key={item.id}
              className={`flex items-center py-3 px-4 text-[var(--text-primary)] transition-all duration-200 border-none bg-transparent cursor-pointer w-full text-left rounded-lg text-sm hover:bg-[var(--bg-hover)] ${
                activeSection === item.id
                  ? "bg-[var(--bg-hover)] font-medium"
                  : ""
              }`}
              onClick={() => setActiveSection(item.id)}
            >
              <span className="w-5 h-5 flex items-center justify-center mr-3">
                {item.icon}
              </span>
              <span>{item.label}</span>
            </button>
          ))}
        </div>

        <div className="border-t border-[var(--border-color)] p-4">
          <button
            onClick={() => setActiveSection("settings")}
            className="flex items-center py-3 px-4 text-[var(--text-secondary)] transition-all duration-200 border-none bg-transparent cursor-pointer w-full text-left rounded-lg text-sm hover:bg-[var(--bg-hover)] hover:text-[var(--text-primary)]"
          >
            <span className="w-5 h-5 flex items-center justify-center mr-3">
              <FaCog className="text-[var(--primary-color)]" />
            </span>
            <span>Ajustes</span>
          </button>
          <button
            className="flex items-center py-3 px-4 text-[var(--text-secondary)] transition-all duration-200 border-none bg-transparent cursor-pointer w-full text-left rounded-lg text-sm hover:bg-[var(--bg-hover)] hover:text-[var(--text-primary)]"
            onClick={handleLogout}
          >
            <span className="w-5 h-5 flex items-center justify-center mr-3">
              <FaSignOutAlt className="text-[var(--primary-color)]" />
            </span>
            <span>Cerrar Sesión</span>
          </button>
          <button
            className="flex items-center py-3 px-4 text-red-600 transition-all duration-200 border-none bg-transparent cursor-pointer w-full text-left rounded-lg text-sm hover:bg-red-50"
            onClick={() => {
              setShowDeleteConfirm(true);
            }}
          >
            <span className="w-5 h-5 flex items-center justify-center mr-3">
              <FaTrash className="text-red-600" />
            </span>
            <span>Eliminar Cuenta</span>
          </button>
        </div>
      </div>

      {/* Barra de navegación inferior para móvil */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-50 shadow-lg">
        <div className="flex justify-around items-center h-14 px-1">
          {menuItems
            .filter((item) => item.id !== "resume")
            .map((item) => (
              <button
                key={item.id}
                className={`flex flex-col items-center justify-center p-1 flex-1 bg-transparent ${
                  activeSection === item.id
                    ? "text-[var(--primary-color)]"
                    : "text-[var(--text-secondary)] hover:text-[var(--text-primary)]"
                }`}
                onClick={() => setActiveSection(item.id)}
              >
                <span className="text-lg mb-0.5">{item.icon}</span>
                <span className="text-[10px] truncate">{item.label}</span>
              </button>
            ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
