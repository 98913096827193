import React, { useState } from "react";
import { FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import axios from "axios";
import { buildUrl } from "../utils/url";
import { formatCurrency } from "../utils/format";

const Goals = ({
  goals,
  onAddGoal,
  onUpdateGoal,
  onDeleteGoal,
  currentIncomes,
  currentExpenses,
  currentInvestments,
}) => {
  const [showNewGoalForm, setShowNewGoalForm] = useState(false);
  const [editingGoalIndex, setEditingGoalIndex] = useState(null);
  const [editingGoal, setEditingGoal] = useState(null);
  const [newGoal, setNewGoal] = useState({
    name: "",
    targetAmount: "",
    currentAmount: "0",
    targetDate: "",
  });
  const [recommendations, setRecommendations] = useState({});
  const [loadingRecommendations, setLoadingRecommendations] = useState({});

  const handleStartEdit = (index, goal) => {
    setEditingGoalIndex(index);
    setEditingGoal({ ...goal });
  };

  const handleEditChange = (field, value) => {
    setEditingGoal((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    onUpdateGoal(editingGoalIndex, {
      ...editingGoal,
      targetAmount: parseFloat(editingGoal.targetAmount),
      currentAmount: parseFloat(editingGoal.currentAmount),
    });
    setEditingGoalIndex(null);
    setEditingGoal(null);
  };

  const calculateProgress = (currentAmount, targetAmount) => {
    return (currentAmount / targetAmount) * 100;
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("es-ES", {
      year: "numeric",
      month: "long",
    });
  };

  const calculateMonthlyTargetAndProgress = (goal) => {
    const today = new Date();
    const targetDate = new Date(goal.targetDate);
    const monthsRemaining =
      (targetDate.getFullYear() - today.getFullYear()) * 12 +
      (targetDate.getMonth() - today.getMonth());

    if (monthsRemaining <= 0) return null;

    const remainingAmount = goal.targetAmount - goal.currentAmount;
    const requiredMonthlySaving = remainingAmount / monthsRemaining;

    const currentMonthlySaving =
      currentIncomes - currentExpenses - currentInvestments;
    const isOnTrack = currentMonthlySaving >= requiredMonthlySaving;

    return {
      requiredMonthlySaving,
      currentMonthlySaving,
      isOnTrack,
      monthsRemaining,
    };
  };

  const getRecommendations = async (goal) => {
    try {
      setLoadingRecommendations((prev) => ({ ...prev, [goal.name]: true }));

      const response = await axios({
        method: "post",
        url: buildUrl(process.env.REACT_APP_API_URL, "auth/analyze-goal"),
        data: {
          goal,
          currentIncomes,
          currentExpenses,
          currentInvestments,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        timeout: 30000,
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        },
        maxRedirects: 0,
        proxy: false,
      });

      if (response.data.success) {
        setRecommendations((prev) => ({
          ...prev,
          [goal.name]: response.data.recommendations,
        }));
      }
    } catch (error) {
      console.error("Error al obtener recomendaciones:", {
        message: error.message,
        code: error.code,
        response: error.response?.data,
        config: error.config,
      });
    } finally {
      setLoadingRecommendations((prev) => ({ ...prev, [goal.name]: false }));
    }
  };

  return (
    <div className="p-4 w-full">
      <div className="grid grid-cols-1 gap-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
            <h2 className="text-xl font-semibold text-[var(--text-primary)]">
              Objetivos de Ahorro
            </h2>
          </div>

          <div className="md:col-span-2 bg-gradient-primary rounded-xl p-4 shadow-[var(--card-shadow)]">
            <h3 className="text-lg text-[var(--text-primary)]">
              Gestión de Objetivos
            </h3>
            <p className="text-[var(--text-primary)]">
              Establece tus metas financieras y realiza un seguimiento de tu
              progreso
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          {goals.map((goal, index) => (
            <div
              key={index}
              className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]"
            >
              {editingGoalIndex === index ? (
                <div className="flex flex-col sm:flex-row items-center gap-3">
                  <div className="flex flex-col sm:flex-row gap-3 w-full items-center">
                    <input
                      type="text"
                      value={editingGoal.name}
                      onChange={(e) => handleEditChange("name", e.target.value)}
                      className="w-full p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                      placeholder="Nombre del objetivo"
                    />
                    <input
                      type="number"
                      value={editingGoal.targetAmount}
                      onChange={(e) =>
                        handleEditChange("targetAmount", e.target.value)
                      }
                      className="w-full sm:w-32 p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                      placeholder="Objetivo €"
                    />
                    <input
                      type="number"
                      value={editingGoal.currentAmount}
                      onChange={(e) =>
                        handleEditChange("currentAmount", e.target.value)
                      }
                      className="w-full sm:w-32 p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                      placeholder="Actual €"
                    />
                    <input
                      type="date"
                      value={editingGoal.targetDate.split("T")[0]}
                      onChange={(e) =>
                        handleEditChange("targetDate", e.target.value)
                      }
                      className="w-full sm:w-auto p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                    />
                  </div>
                  <div className="flex gap-2 w-full sm:w-auto">
                    <button
                      onClick={handleUpdate}
                      className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                    >
                      Guardar
                    </button>
                    <button
                      onClick={() => {
                        setEditingGoalIndex(null);
                        setEditingGoal(null);
                      }}
                      className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-4">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-lg font-semibold text-[var(--text-primary)]">
                        {goal.name}
                      </h3>
                      <p className="text-sm text-[var(--text-secondary)]">
                        Meta para {formatDate(goal.targetDate)}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleStartEdit(index, goal)}
                        className="text-[var(--text-secondary)] hover:text-[var(--accent-color)] transition-colors bg-transparent"
                      >
                        <FaPencilAlt className="text-sm" />
                      </button>
                      <button
                        onClick={() => onDeleteGoal(index)}
                        className="text-[var(--text-secondary)] hover:text-red-400 transition-colors bg-transparent"
                      >
                        <FaTrash className="text-sm" />
                      </button>
                    </div>
                  </div>

                  <div className="bg-[var(--bg-primary)] rounded-xl p-4">
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-[var(--text-secondary)]">
                        {formatCurrency(goal.currentAmount)} de{" "}
                        {formatCurrency(goal.targetAmount)}
                      </span>
                      <span className="text-[var(--text-secondary)]">
                        {calculateProgress(
                          goal.currentAmount,
                          goal.targetAmount
                        ).toFixed(1)}
                        %
                      </span>
                    </div>
                    <div className="w-full bg-[var(--bg-secondary)] rounded-full h-2">
                      <div
                        className="bg-[var(--accent-color)] h-2 rounded-full transition-all duration-300"
                        style={{
                          width: `${calculateProgress(
                            goal.currentAmount,
                            goal.targetAmount
                          )}%`,
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="bg-[var(--bg-primary)] rounded-xl p-4">
                    {(() => {
                      const analysis = calculateMonthlyTargetAndProgress(goal);
                      if (!analysis)
                        return (
                          <p className="text-[var(--text-secondary)] text-sm">
                            La fecha objetivo ya ha pasado
                          </p>
                        );

                      return (
                        <div className="flex flex-col gap-2">
                          <div className="flex justify-between text-sm">
                            <span className="text-[var(--text-secondary)]">
                              Ahorro mensual necesario:
                            </span>
                            <span
                              className={`font-medium ${
                                analysis.isOnTrack
                                  ? "text-green-500"
                                  : "text-red-500"
                              }`}
                            >
                              {analysis.requiredMonthlySaving.toFixed(2)}€
                            </span>
                          </div>
                          <div className="flex justify-between text-sm">
                            <span className="text-[var(--text-secondary)]">
                              Ahorro mensual actual:
                            </span>
                            <span className="font-medium text-[var(--text-primary)]">
                              {analysis.currentMonthlySaving.toFixed(2)}€
                            </span>
                          </div>
                          <div className="mt-2 text-sm">
                            <p
                              className={`${
                                analysis.isOnTrack
                                  ? "text-green-500"
                                  : "text-red-500"
                              }`}
                            >
                              {analysis.isOnTrack
                                ? "✅ Vas bien encaminado para alcanzar tu objetivo"
                                : `⚠️ Necesitas aumentar tu ahorro mensual en ${(
                                    analysis.requiredMonthlySaving -
                                    analysis.currentMonthlySaving
                                  ).toFixed(2)}€ para alcanzar tu objetivo`}
                            </p>
                            <p className="text-[var(--text-secondary)] mt-1">
                              Tiempo restante: {analysis.monthsRemaining} meses
                            </p>
                          </div>
                        </div>
                      );
                    })()}
                  </div>

                  <div className="mt-2">
                    <button
                      onClick={() => getRecommendations(goal)}
                      className="text-[var(--text-primary)] hover:text-[var(--accent-color)] transition-colors text-sm"
                    >
                      {loadingRecommendations[goal.name]
                        ? "Generando recomendaciones..."
                        : recommendations[goal.name]
                        ? "Actualizar recomendaciones"
                        : "Obtener recomendaciones personalizadas"}
                    </button>

                    {recommendations[goal.name] && (
                      <div className="mt-3 p-4 bg-[var(--bg-primary)] rounded-xl border border-[var(--border-color)]">
                        <h4 className="text-sm font-medium text-[var(--text-primary)] mb-2">
                          Recomendaciones personalizadas:
                        </h4>
                        <div
                          className="text-sm text-[var(--text-secondary)]"
                          dangerouslySetInnerHTML={{
                            __html: recommendations[goal.name],
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}

          {showNewGoalForm ? (
            <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
              <div className="flex flex-col sm:flex-row items-center gap-3">
                <div className="flex flex-col sm:flex-row gap-3 w-full items-center">
                  <input
                    type="text"
                    value={newGoal.name}
                    onChange={(e) =>
                      setNewGoal({ ...newGoal, name: e.target.value })
                    }
                    placeholder="Nombre del objetivo"
                    className="w-full p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                  />
                  <input
                    type="number"
                    value={newGoal.targetAmount}
                    onChange={(e) =>
                      setNewGoal({ ...newGoal, targetAmount: e.target.value })
                    }
                    placeholder="Cantidad objetivo"
                    className="w-full sm:w-32 p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                  />
                  <input
                    type="date"
                    value={newGoal.targetDate}
                    onChange={(e) =>
                      setNewGoal({ ...newGoal, targetDate: e.target.value })
                    }
                    className="w-full sm:w-auto p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                  />
                </div>
                <div className="flex gap-2 w-full sm:w-auto">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onAddGoal({
                        ...newGoal,
                        targetAmount: parseFloat(newGoal.targetAmount),
                        currentAmount: parseFloat(newGoal.currentAmount),
                      });
                      setNewGoal({
                        name: "",
                        targetAmount: "",
                        currentAmount: "0",
                        targetDate: "",
                      });
                      setShowNewGoalForm(false);
                    }}
                    className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                  >
                    <FaPlus className="text-sm inline mr-2" /> Añadir
                  </button>
                  <button
                    onClick={() => setShowNewGoalForm(false)}
                    className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <button
              onClick={() => setShowNewGoalForm(true)}
              className="w-full p-3 bg-[var(--bg-secondary)] rounded-lg text-[var(--text-secondary)] hover:bg-[var(--bg-hover)] hover:text-[var(--text-primary)] transition-all flex items-center justify-center gap-2"
            >
              <FaPlus /> Añadir objetivo
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Goals;
