import React from "react";
import { formatCurrency } from "../utils/format";
import { Doughnut } from "react-chartjs-2";

const MonthlyResume = ({ incomes, expenses, investments, patrimony }) => {
  const totalIncomes =
    incomes?.reduce((acc, curr) => acc + curr.amount, 0) || 0;
  const totalExpenses =
    expenses?.reduce((acc, curr) => acc + curr.amount, 0) || 0;
  const totalInvestments =
    investments?.reduce((acc, curr) => acc + curr.amount, 0) || 0;
  const availableBalance = totalIncomes - totalExpenses - totalInvestments;
  const netWorth = patrimony?.reduce((acc, curr) => acc + curr.amount, 0) || 0;

  const prepareChartData = (data) => {
    return {
      labels: data.map((item) => item.name),
      datasets: [
        {
          data: data.map((item) => item.amount),
          backgroundColor: [
            "rgb(16, 185, 129)", // emerald-500
            "rgb(168, 85, 247)", // purple-500
            "rgb(236, 72, 153)", // pink-500
            "rgb(249, 115, 22)", // orange-500
            "rgb(234, 179, 8)", // yellow-500
            "rgb(14, 165, 233)", // sky-500
            "rgb(79, 70, 229)", // indigo-600
          ],
          borderWidth: 0.75,
        },
      ],
    };
  };

  return (
    <div className="p-4 w-full">
      <div className="grid grid-cols-1 gap-4">
        <div className="space-y-4">
          {/* Fila superior */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Balance disponible */}
            <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
              <h3 className="text-lg text-[var(--text-secondary)]">
                Balance Disponible
              </h3>
              <p className="text-2xl font-bold text-[var(--accent-color)]">
                {formatCurrency(availableBalance)}
              </p>
            </div>

            {/* Net Worth */}
            <div className="md:col-span-2 bg-gradient-primary rounded-xl p-4 shadow-[var(--card-shadow)]">
              <h3 className="text-lg text-[var(--text-primary)]">
                Patrimonio Total
              </h3>
              <p className="text-3xl font-bold text-[var(--text-primary)]">
                {formatCurrency(netWorth)}
              </p>
            </div>
          </div>

          {/* Segunda fila */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Ingresos */}
            <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
              <h3 className="text-lg text-[var(--text-secondary)]">Ingresos</h3>
              <p className="text-xl font-bold text-[var(--text-primary)]">
                {formatCurrency(totalIncomes)}
              </p>
            </div>

            {/* Gastos */}
            <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
              <h3 className="text-lg text-[var(--text-secondary)]">Gastos</h3>
              <p className="text-xl font-bold text-[var(--text-primary)]">
                {formatCurrency(totalExpenses)}
              </p>
            </div>

            {/* Inversiones */}
            <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
              <h3 className="text-lg text-[var(--text-secondary)]">
                Inversiones
              </h3>
              <p className="text-xl font-bold text-[var(--text-primary)]">
                {formatCurrency(totalInvestments)}
              </p>
            </div>
          </div>

          {/* Tercera fila */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Gráfico de Ingresos */}
            <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
              <h3 className="text-lg text-[var(--text-secondary)] mb-4">
                Clasificación de Ingresos
              </h3>
              {incomes && incomes.length > 0 ? (
                <div className="relative">
                  <Doughnut
                    data={prepareChartData(incomes)}
                    options={{
                      rotation: -90,
                      circumference: 180,
                      cutout: "75%",
                      aspectRatio: 2,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      layout: {
                        padding: {
                          top: 10,
                        },
                      },
                    }}
                  />
                </div>
              ) : (
                <p className="text-center text-[var(--text-secondary)]">
                  No hay ingresos registrados
                </p>
              )}
            </div>

            {/* Distribución de Activos */}
            <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
              <h3 className="text-lg text-[var(--text-secondary)] mb-4">
                Distribución de Activos
              </h3>
              {patrimony && patrimony.length > 0 ? (
                <div className="relative">
                  <Doughnut
                    data={prepareChartData(patrimony)}
                    options={{
                      rotation: -90,
                      circumference: 180,
                      cutout: "75%",
                      aspectRatio: 2,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      layout: {
                        padding: {
                          top: 10,
                        },
                      },
                    }}
                  />
                </div>
              ) : (
                <p className="text-center text-[var(--text-secondary)]">
                  No hay activos registrados
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyResume;
