import React, { useState, useEffect } from "react";
import { FaPencilAlt, FaPlus, FaTrash, FaSync } from "react-icons/fa";
import { Doughnut } from "react-chartjs-2";
import { formatCurrency } from "../utils/format";
import Notification from "./Notification";
import axios from "axios";
import { buildUrl } from "../utils/url";
import CryptoPortfolio from "./CryptoPortfolio";

const Patrimonio = ({
  patrimony,
  onAddPatrimony,
  onUpdatePatrimony,
  onDeletePatrimony,
}) => {
  const [showNewForm, setShowNewForm] = React.useState(false);
  const [editingIndex, setEditingIndex] = React.useState(null);
  const [editingValues, setEditingValues] = React.useState(null);
  const [notification, setNotification] = React.useState(null);
  const [formData, setFormData] = useState({
    name: "",
    amount: "",
    category: "",
    description: "",
  });
  const [activeTab, setActiveTab] = useState("general"); // "general" o "crypto"
  const [cryptos, setCryptos] = useState([]);

  useEffect(() => {
    const fetchCryptos = async () => {
      try {
        const response = await axios.get(
          buildUrl(process.env.REACT_APP_API_URL, "auth/cryptos"),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.data.success) {
          setCryptos(response.data.cryptos);
        }
      } catch (error) {
        console.error("Error al cargar cryptos:", error);
      }
    };

    fetchCryptos();
  }, []);

  const handleCryptoUpdate = (updatedCryptos) => {
    setCryptos(updatedCryptos);
  };

  const calculateTotalPatrimony = () => {
    // Valor del patrimonio general
    const generalPatrimonyValue =
      patrimony?.reduce((acc, curr) => acc + curr.amount, 0) || 0;

    // Valor de las criptomonedas usando el precio actual
    const cryptoValue =
      cryptos?.reduce((acc, crypto) => {
        const quantity = parseFloat(crypto.quantity);
        const currentPrice =
          parseFloat(crypto.currentPrice) || parseFloat(crypto.purchasePrice);
        const value = quantity * currentPrice;
        return acc + (isNaN(value) ? 0 : value);
      }, 0) || 0;

    return generalPatrimonyValue + cryptoValue;
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
  };

  const resetForm = () => {
    setFormData({
      name: "",
      amount: "",
      category: "",
      description: "",
    });
    setShowNewForm(false);
  };

  const handleEditChange = (field, value) => {
    setEditingValues((prev) => ({
      ...prev,
      [field]: field === "amount" ? parseFloat(value) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newPatrimony = {
      ...formData,
      amount: parseFloat(formData.amount),
    };

    try {
      if (editingIndex !== null) {
        await onUpdatePatrimony(editingIndex, newPatrimony);
      } else {
        await onAddPatrimony(newPatrimony);
      }
      resetForm();
    } catch (error) {
      showNotification("Error al guardar el activo", "error");
    }
  };

  const handleSaveEdit = (index) => {
    if (editingValues) {
      try {
        onUpdatePatrimony(index, editingValues);
        showNotification(
          `Activo "${editingValues.name}" actualizado correctamente`,
          "success"
        );
        setEditingIndex(null);
        setEditingValues(null);
      } catch (error) {
        showNotification(
          `Error al actualizar el activo "${editingValues.name}"`,
          "error"
        );
      }
    }
  };

  const handleStartEdit = (index, item) => {
    setEditingIndex(index);
    setEditingValues({ ...item });
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setEditingValues(null);
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setFormData((prev) => ({
      ...prev,
      category,
    }));
  };

  const handleQuickPriceUpdate = async (index) => {
    const item = patrimony[index];
    if (!item.cryptoDetails?.symbol) return;

    try {
      const updatedItem = {
        ...item,
        amount: item.cryptoDetails.quantity * item.cryptoDetails.currentPrice,
      };
      await onUpdatePatrimony(index, updatedItem);
      showNotification("Precio actualizado correctamente", "success");
    } catch (error) {
      showNotification("Error al actualizar el precio", "error");
    }
  };

  return (
    <>
      <div className="p-4 w-full">
        {/* Tabs de navegación */}
        <div className="flex space-x-2 mb-4">
          <button
            onClick={() => setActiveTab("general")}
            className={`px-4 py-2 rounded-lg transition-colors ${
              activeTab === "general"
                ? "bg-[var(--accent-color)] text-white"
                : "bg-[var(--bg-secondary)] text-[var(--text-primary)] hover:bg-[var(--bg-hover)]"
            }`}
          >
            Patrimonio General
          </button>
          <button
            onClick={() => setActiveTab("crypto")}
            className={`px-4 py-2 rounded-lg transition-colors ${
              activeTab === "crypto"
                ? "bg-[var(--accent-color)] text-white"
                : "bg-[var(--bg-secondary)] text-[var(--text-primary)] hover:bg-[var(--bg-hover)]"
            }`}
          >
            Portfolio Crypto
          </button>
        </div>

        {activeTab === "general" ? (
          <div className="grid grid-cols-1 gap-4">
            {/* Título y Total */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {/* Título */}
              <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
                <h2 className="text-xl font-semibold text-[var(--text-primary)]">
                  Patrimonio Total
                </h2>
              </div>

              {/* Total Patrimonio */}
              <div className="md:col-span-2 bg-gradient-primary rounded-xl p-4 shadow-[var(--card-shadow)]">
                <h3 className="text-lg text-[var(--text-primary)]">
                  Total Patrimonio
                </h3>
                <p className="text-3xl font-bold text-[var(--text-primary)]">
                  {formatCurrency(calculateTotalPatrimony())}
                </p>
                <div className="mt-2 text-sm text-[var(--text-primary)]">
                  <span>
                    Activos:{" "}
                    {formatCurrency(
                      patrimony?.reduce((acc, curr) => acc + curr.amount, 0) ||
                        0
                    )}
                  </span>
                  <span className="mx-2">·</span>
                  <span>
                    Cryptos:{" "}
                    {formatCurrency(
                      cryptos?.reduce((acc, crypto) => {
                        const quantity = parseFloat(crypto.quantity);
                        const currentPrice =
                          parseFloat(crypto.currentPrice) ||
                          parseFloat(crypto.purchasePrice);
                        const value = quantity * currentPrice;
                        return acc + (isNaN(value) ? 0 : value);
                      }, 0) || 0
                    )}
                  </span>
                </div>
              </div>
            </div>

            {/* Lista de Activos y Gráfico */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {/* Lista de Activos - ocupa 2 columnas */}
              <div className="md:col-span-2 bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
                <div className="space-y-3">
                  {patrimony.map((item, index) => (
                    <div
                      key={index}
                      className="bg-[var(--bg-primary)] rounded-lg p-3 hover:bg-[var(--bg-hover)] transition-colors"
                    >
                      {editingIndex === index ? (
                        <div className="flex flex-col sm:flex-row items-center gap-3">
                          <div className="flex flex-col sm:flex-row gap-3 w-full items-center">
                            <input
                              type="text"
                              value={editingValues.name}
                              onChange={(e) =>
                                handleEditChange("name", e.target.value)
                              }
                              className="w-full p-2 bg-[var(--bg-secondary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                              placeholder="Nombre"
                            />
                            <input
                              type="number"
                              value={editingValues.amount}
                              onChange={(e) =>
                                handleEditChange("amount", e.target.value)
                              }
                              className="w-full sm:w-32 p-2 bg-[var(--bg-secondary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                              placeholder="Cantidad"
                            />
                          </div>
                          <div className="flex gap-2 w-full sm:w-auto">
                            <button
                              onClick={() => handleSaveEdit(index)}
                              className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                            >
                              Guardar
                            </button>
                            <button
                              onClick={handleCancelEdit}
                              className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                            >
                              Cancelar
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-between">
                          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 min-w-0 flex-1 mr-2">
                            <span className="text-[var(--text-primary)] truncate max-w-[150px] sm:max-w-none">
                              {item.name}
                            </span>
                            <span className="text-[var(--accent-color)] whitespace-nowrap">
                              {formatCurrency(item.amount)}
                            </span>
                          </div>
                          <div className="flex gap-2 flex-shrink-0">
                            <button
                              onClick={() => handleStartEdit(index, item)}
                              className="text-[var(--text-secondary)] hover:text-[var(--accent-color)] transition-colors bg-transparent"
                            >
                              <FaPencilAlt className="text-sm" />
                            </button>
                            <button
                              onClick={() => onDeletePatrimony(index)}
                              className="text-[var(--text-secondary)] hover:text-red-400 transition-colors bg-transparent"
                            >
                              <FaTrash className="text-sm" />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                  {showNewForm ? (
                    <form onSubmit={handleSubmit} className="mb-6 space-y-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label className="block text-sm text-[var(--text-secondary)] mb-1">
                            Nombre
                          </label>
                          <input
                            type="text"
                            value={formData.name}
                            onChange={(e) =>
                              setFormData((prev) => ({
                                ...prev,
                                name: e.target.value,
                              }))
                            }
                            className="w-full p-2 rounded-lg bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)]"
                            placeholder="Nombre del activo"
                            required
                          />
                        </div>
                        <div>
                          <label className="block text-sm text-[var(--text-secondary)] mb-1">
                            Categoría
                          </label>
                          <select
                            value={formData.category}
                            onChange={handleCategoryChange}
                            className="w-full p-2 rounded-lg bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)]"
                            required
                          >
                            <option value="">Seleccionar categoría</option>
                            <option value="Efectivo">Efectivo</option>
                            <option value="Cuentas Bancarias">
                              Cuentas Bancarias
                            </option>
                            <option value="Inmuebles">Inmuebles</option>
                            <option value="Vehículos">Vehículos</option>
                            <option value="Acciones">Acciones</option>
                            <option value="Fondos de Inversión">
                              Fondos de Inversión
                            </option>
                            <option value="Criptomonedas">Criptomonedas</option>
                            <option value="Arte y Coleccionables">
                              Arte y Coleccionables
                            </option>
                            <option value="Otros Activos">Otros Activos</option>
                          </select>
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm text-[var(--text-secondary)] mb-1">
                          Valor
                        </label>
                        <input
                          type="number"
                          value={formData.amount}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              amount: parseFloat(e.target.value),
                            }))
                          }
                          className="w-full p-2 rounded-lg bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)]"
                          placeholder="0.00"
                          step="any"
                          required
                        />
                      </div>

                      <div>
                        <label className="block text-sm text-[var(--text-secondary)] mb-1">
                          Descripción
                        </label>
                        <textarea
                          value={formData.description}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              description: e.target.value,
                            }))
                          }
                          className="w-full p-2 rounded-lg bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)]"
                          rows="2"
                          placeholder="Descripción (opcional)"
                        />
                      </div>

                      <div className="flex gap-2 w-full sm:w-auto">
                        <button
                          type="submit"
                          className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                        >
                          <FaPlus className="text-sm inline mr-2" /> Añadir
                        </button>
                        <button
                          onClick={() => setShowNewForm(false)}
                          className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                        >
                          Cancelar
                        </button>
                      </div>
                    </form>
                  ) : (
                    <button
                      onClick={() => setShowNewForm(true)}
                      className="w-full p-3 bg-[var(--bg-secondary)] rounded-lg text-[var(--text-secondary)] hover:bg-[var(--bg-hover)] hover:text-[var(--text-primary)] transition-all flex items-center justify-center gap-2"
                    >
                      <FaPlus /> Añadir activo
                    </button>
                  )}
                </div>
              </div>

              {/* Gráfico - ocupa 1 columna */}
              <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
                {patrimony.length > 0 ? (
                  <div className="relative">
                    <Doughnut
                      data={{
                        labels: patrimony.map((item) => item.name),
                        datasets: [
                          {
                            data: patrimony.map((item) => item.amount),
                            backgroundColor: [
                              "rgb(16, 185, 129)", // emerald-500
                              "rgb(168, 85, 247)", // purple-500
                              "rgb(236, 72, 153)", // pink-500
                              "rgb(249, 115, 22)", // orange-500
                              "rgb(234, 179, 8)", // yellow-500
                              "rgb(14, 165, 233)", // sky-500
                              "rgb(79, 70, 229)", // indigo-600
                            ],
                            borderWidth: 0,
                          },
                        ],
                      }}
                      options={{
                        rotation: -90,
                        circumference: 180,
                        cutout: "75%",
                        plugins: {
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            callbacks: {
                              label: function (context) {
                                const label = context.label || "";
                                const value = context.raw || 0;
                                const total = context.dataset.data.reduce(
                                  (acc, data) => acc + data,
                                  0
                                );
                                const percentage = (
                                  (value / total) *
                                  100
                                ).toFixed(2);
                                return `${label}: ${formatCurrency(
                                  value
                                )} (${percentage}%)`;
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                ) : (
                  <p className="text-center text-[var(--text-secondary)]">
                    No hay activos registrados
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <CryptoPortfolio
            cryptos={cryptos}
            onCryptoUpdate={handleCryptoUpdate}
          />
        )}
      </div>

      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );
};

export default Patrimonio;
