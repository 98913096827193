import React from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const Notification = ({ message, type, onClose }) => {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 2000);

    return () => clearTimeout(timer);
  }, [onClose]);

  const getStyles = () => {
    if (type === "success") {
      return "notification-success";
    }
    return "notification-error";
  };

  return (
    <div
      className={`fixed bottom-4 right-4 px-4 py-3 rounded-lg shadow-lg flex items-center gap-2 z-50 animate-slide-up ${getStyles()}`}
    >
      {type === "success" ? (
        <FaCheckCircle className="text-xl" />
      ) : (
        <FaTimesCircle className="text-xl" />
      )}
      <span>{message}</span>
    </div>
  );
};

export default Notification;
