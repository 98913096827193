import React, { useState } from "react";
import { FaPlus, FaPencilAlt, FaTrash, FaSync } from "react-icons/fa";
import axios from "axios";
import { buildUrl } from "../utils/url";
import { format } from "date-fns";
import { formatCurrency } from "../utils/format";
import Notification from "./Notification";
import { Doughnut } from "react-chartjs-2";

const CryptoPortfolio = ({ cryptos, onCryptoUpdate }) => {
  const [showForm, setShowForm] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [formData, setFormData] = useState({
    symbol: "",
    quantity: "",
    purchasePrice: "",
    purchaseDate: format(new Date(), "yyyy-MM-dd"),
  });
  const [notification, setNotification] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const endpoint =
        editingIndex !== null
          ? `cryptos/update/${editingIndex}`
          : "cryptos/add";

      const dataToSend = {
        symbol: formData.symbol.trim().toUpperCase(),
        quantity: parseFloat(formData.quantity) || 0,
        purchasePrice: parseFloat(formData.purchasePrice) || 0,
        purchaseDate: new Date(formData.purchaseDate).toISOString(),
      };

      const response = await axios.post(
        buildUrl(process.env.REACT_APP_API_URL, `auth/${endpoint}`),
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        onCryptoUpdate(response.data.cryptos);
        resetForm();
        showNotification(
          `Crypto ${
            editingIndex !== null ? "actualizada" : "añadida"
          } correctamente`,
          "success"
        );
      }
    } catch (error) {
      console.error("Error:", error);
      showNotification(
        error.response?.data?.message || "Error al guardar",
        "error"
      );
    }
  };

  const handleDelete = async (index) => {
    try {
      const response = await axios.delete(
        buildUrl(process.env.REACT_APP_API_URL, `auth/cryptos/${index}`),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        onCryptoUpdate(response.data.cryptos);
        showNotification("Crypto eliminada correctamente", "success");
      }
    } catch (error) {
      showNotification("Error al eliminar", "error");
    }
  };

  const resetForm = () => {
    setFormData({
      symbol: "",
      quantity: "",
      purchasePrice: "",
      purchaseDate: format(new Date(), "yyyy-MM-dd"),
    });
    setShowForm(false);
    setEditingIndex(null);
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleEdit = (crypto, index) => {
    setEditingIndex(index);
    setFormData({
      symbol: crypto.symbol || "",
      quantity: crypto.quantity?.toString() || "",
      purchasePrice: crypto.purchasePrice?.toString() || "",
      purchaseDate: crypto.purchaseDate
        ? format(new Date(crypto.purchaseDate), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd"),
    });
    setShowForm(true);
  };

  const calculateTotalValue = () => {
    return cryptos.reduce((total, crypto) => {
      const quantity = parseFloat(crypto.quantity);
      const currentPrice =
        parseFloat(crypto.currentPrice) || parseFloat(crypto.purchasePrice);
      const value = quantity * currentPrice;
      return total + (isNaN(value) ? 0 : value);
    }, 0);
  };

  const renderCryptoItem = (crypto, index) => {
    const purchasePrice = parseFloat(crypto.purchasePrice);
    const currentPrice = parseFloat(crypto.currentPrice) || purchasePrice;
    const quantity = parseFloat(crypto.quantity);

    const totalInvested = quantity * purchasePrice;
    const currentValue = quantity * currentPrice;
    const profit = currentValue - totalInvested;
    const profitPercentage = (profit / totalInvested) * 100;

    return (
      <div
        key={index}
        className="flex items-center justify-between p-4 bg-[var(--bg-primary)] rounded-lg"
      >
        <div>
          <div className="flex items-center gap-2">
            <span className="font-medium text-[var(--text-primary)]">
              {crypto.symbol}
            </span>
            <span className="text-[var(--text-secondary)]">·</span>
            <span className="text-[var(--text-primary)]">
              {quantity} tokens
            </span>
          </div>
          <div className="text-sm mt-1 space-x-2">
            <span className="text-[var(--text-secondary)]">
              Compra: {formatCurrency(purchasePrice)}
            </span>
            <span className="text-[var(--text-secondary)]">·</span>
            <span className="text-[var(--text-secondary)]">
              Actual: {formatCurrency(currentPrice)}
            </span>
            <span className="text-[var(--text-secondary)]">·</span>
            <span
              className={`${profit >= 0 ? "text-green-500" : "text-red-500"}`}
            >
              {profit >= 0 ? "+" : ""}
              {profitPercentage.toFixed(2)}%
            </span>
          </div>
          <div className="text-sm mt-1">
            <span className="text-[var(--accent-color)]">
              Valor actual: {formatCurrency(currentValue)}
            </span>
            <span className="mx-2 text-[var(--text-secondary)]">·</span>
            <span
              className={`${profit >= 0 ? "text-green-500" : "text-red-500"}`}
            >
              {profit >= 0 ? "+" : ""}
              {formatCurrency(profit)}
            </span>
          </div>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => handleUpdatePrice(index)}
            className="text-[var(--text-secondary)] hover:text-[var(--accent-color)]"
            title="Actualizar precio"
          >
            <FaSync />
          </button>
          <button
            onClick={() => handleEdit(crypto, index)}
            className="text-[var(--text-secondary)] hover:text-[var(--accent-color)]"
          >
            <FaPencilAlt />
          </button>
          <button
            onClick={() => handleDelete(index)}
            className="text-[var(--text-secondary)] hover:text-red-500"
          >
            <FaTrash />
          </button>
        </div>
      </div>
    );
  };

  const handleUpdatePrice = async (index) => {
    try {
      const newPrice = prompt("Introduce el precio actual:", "0");
      if (newPrice === null) return; // Usuario canceló

      const response = await axios.post(
        buildUrl(
          process.env.REACT_APP_API_URL,
          `auth/cryptos/update-price/${index}`
        ),
        { currentPrice: parseFloat(newPrice) },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        onCryptoUpdate(response.data.cryptos);
        showNotification("Precio actualizado correctamente", "success");
      }
    } catch (error) {
      showNotification("Error al actualizar el precio", "error");
    }
  };

  const renderChart = () => {
    if (cryptos.length === 0) return null;

    const chartData = {
      labels: cryptos.map((crypto) => crypto.symbol),
      datasets: [
        {
          data: cryptos.map((crypto) => {
            const currentValue =
              parseFloat(crypto.quantity) *
              (parseFloat(crypto.currentPrice) ||
                parseFloat(crypto.purchasePrice));
            return isNaN(currentValue) ? 0 : currentValue;
          }),
          backgroundColor: [
            "rgb(16, 185, 129)", // emerald-500
            "rgb(168, 85, 247)", // purple-500
            "rgb(236, 72, 153)", // pink-500
            "rgb(249, 115, 22)", // orange-500
            "rgb(234, 179, 8)", // yellow-500
            "rgb(14, 165, 233)", // sky-500
            "rgb(79, 70, 229)", // indigo-600
          ],
          borderWidth: 0,
        },
      ],
    };

    const options = {
      responsive: true,
      cutout: "70%",
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          labels: {
            color: "var(--text-primary)",
            padding: 20,
            font: {
              size: 12,
            },
            generateLabels: (chart) => {
              const data = chart.data;
              const total = data.datasets[0].data.reduce(
                (sum, value) => sum + value,
                0
              );

              return data.labels.map((label, i) => {
                const value = data.datasets[0].data[i];
                const percentage = ((value / total) * 100).toFixed(1);
                return {
                  text: `${label} - ${formatCurrency(value)} (${percentage}%)`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  hidden: false,
                  index: i,
                };
              });
            },
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const value = context.raw;
              const total = context.dataset.data.reduce(
                (sum, value) => sum + value,
                0
              );
              const percentage = ((value / total) * 100).toFixed(1);
              return `${formatCurrency(value)} (${percentage}%)`;
            },
          },
        },
      },
    };

    return (
      <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)] mt-4">
        <h3 className="text-lg font-semibold text-[var(--text-primary)] mb-4">
          Distribución del Portfolio
        </h3>
        <div className="relative aspect-square max-w-[400px] mx-auto">
          <Doughnut data={chartData} options={options} />
        </div>
      </div>
    );
  };

  return (
    <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
      <div className="flex justify-between items-center mb-4">
        <div>
          <h2 className="text-xl font-semibold text-[var(--text-primary)]">
            Mis Criptomonedas
          </h2>
          <p className="text-sm text-[var(--text-secondary)]">
            Valor total: {formatCurrency(calculateTotalValue())}
          </p>
        </div>
        {showForm ? (
          <form onSubmit={handleSubmit} className="mb-6 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm text-[var(--text-secondary)] mb-1">
                  Símbolo
                </label>
                <input
                  type="text"
                  value={formData.symbol}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      symbol: e.target.value.toUpperCase(),
                    }))
                  }
                  className="w-full p-2 rounded-lg bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)]"
                  placeholder="BTC"
                  required
                />
              </div>
              <div>
                <label className="block text-sm text-[var(--text-secondary)] mb-1">
                  Cantidad
                </label>
                <input
                  type="number"
                  value={formData.quantity}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      quantity: e.target.value,
                    }))
                  }
                  className="w-full p-2 rounded-lg bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)]"
                  placeholder="0.00"
                  step="any"
                  required
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm text-[var(--text-secondary)] mb-1">
                  Precio de compra (€)
                </label>
                <input
                  type="number"
                  value={formData.purchasePrice}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      purchasePrice: e.target.value,
                    }))
                  }
                  className="w-full p-2 rounded-lg bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)]"
                  placeholder="0.00"
                  step="any"
                  required
                />
              </div>
              <div>
                <label className="block text-sm text-[var(--text-secondary)] mb-1">
                  Fecha de compra
                </label>
                <input
                  type="date"
                  value={formData.purchaseDate}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      purchaseDate: e.target.value,
                    }))
                  }
                  className="w-full p-2 rounded-lg bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)]"
                  required
                />
              </div>
            </div>

            <div className="flex gap-2 w-full sm:w-auto">
              <button
                type="submit"
                className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
              >
                <FaPlus className="text-sm inline mr-2" />{" "}
                {editingIndex !== null ? "Actualizar" : "Añadir"}
              </button>
              <button
                type="button"
                onClick={resetForm}
                className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
              >
                Cancelar
              </button>
            </div>
          </form>
        ) : (
          <button
            onClick={() => setShowForm(true)}
            className="w-[30%] p-3 bg-[var(--bg-secondary)] rounded-lg text-[var(--text-secondary)] hover:bg-[var(--bg-hover)] hover:text-[var(--text-primary)] transition-all flex items-center justify-center gap-2"
          >
            <FaPlus /> Añadir crypto
          </button>
        )}
      </div>

      <div className="space-y-2">
        {cryptos.map((crypto, index) => renderCryptoItem(crypto, index))}
      </div>

      {renderChart()}

      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
};

export default CryptoPortfolio;
