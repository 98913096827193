import React from "react";
import { Link } from "react-router-dom";
import { FaUser, FaEnvelope, FaCalendar } from "react-icons/fa";

const UserInfo = ({ userData }) => {
  if (!userData) return null;

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Avatar y Nombre */}
        <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
          <div className="flex items-center gap-4">
            <div className="w-16 h-16 rounded-full overflow-hidden border-2 border-[var(--accent-color)] bg-white">
              <img
                src={`https://api.dicebear.com/7.x/initials/svg?seed=${userData.username.charAt(
                  0
                )}`}
                alt="Avatar"
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <h2 className="text-xl font-semibold text-[var(--text-primary)]">
                {userData.username}
              </h2>
              <p className="text-[var(--text-secondary)] text-sm">
                Bienvenido de nuevo
              </p>
            </div>
          </div>
        </div>

        {/* Email */}
        <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
          <div className="flex items-center gap-3">
            <FaEnvelope className="text-[var(--accent-color)] text-xl" />
            <div className="flex-1">
              <p className="text-sm text-[var(--text-secondary)] mb-1">Email</p>
              <p className="text-[var(--text-primary)] truncate">
                {userData.email}
              </p>
            </div>
          </div>
        </div>

        {/* Fecha de registro */}
        <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
          <div className="flex items-center gap-3">
            <FaCalendar className="text-[var(--accent-color)] text-xl" />
            <div className="flex-1">
              <p className="text-sm text-[var(--text-secondary)] mb-1">
                Miembro desde
              </p>
              <p className="text-[var(--text-primary)]">
                {new Date(userData.createdAt).toLocaleDateString("es-ES", {
                  year: "numeric",
                  month: "long",
                })}
              </p>
            </div>
          </div>
        </div>

        {/* Enlace para cambiar contraseña */}
        <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
          <Link
            to="/forgot-password"
            className="text-[var(--text-primary)] hover:text-[var(--accent-color)] transition-colors duration-300 flex items-center justify-center gap-2"
          >
            <span>¿Has olvidado o quieres cambiar la contraseña?</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
