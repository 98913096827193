import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaBalanceScale,
  FaGithub,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  const location = useLocation();
  const isInDashboard = location.pathname === "/dashboard";

  return (
    <footer
      className={`
        flex flex-col md:flex-row justify-between items-center px-8 py-4 
        bg-background border-t border-white/[0.125]
        ${isInDashboard ? "md:ml-[250px]" : ""}
        ${isInDashboard ? "pb-20 md:pb-4" : ""}
      `}
    >
      <div className="flex-1 mb-4 md:mb-0">
        <Link
          to="/legal"
          className="flex items-center gap-2 text-text/80 hover:text-primary transition-colors duration-300"
        >
          <FaBalanceScale /> Aviso Legal
        </Link>
      </div>

      <div className="text-text/80 text-center mb-4 md:mb-0">
        Copyright © {new Date().getFullYear()} myfinancebrain.com
      </div>

      <div className="flex-1 flex justify-center md:justify-end gap-4">
        <a
          href="https://github.com/yourusername"
          target="_blank"
          rel="noopener noreferrer"
          className="text-text/80 hover:text-primary transition-colors duration-300"
        >
          <FaGithub size={20} />
        </a>
        <a
          href="https://linkedin.com/in/yourusername"
          target="_blank"
          rel="noopener noreferrer"
          className="text-text/80 hover:text-primary transition-colors duration-300"
        >
          <FaLinkedin size={20} />
        </a>
        <a
          href="https://twitter.com/yourusername"
          target="_blank"
          rel="noopener noreferrer"
          className="text-text/80 hover:text-primary transition-colors duration-300"
        >
          <FaTwitter size={20} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
