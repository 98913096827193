import React from "react";
import { Link } from "react-router-dom";
import {
  FaChartLine,
  FaRobot,
  FaShieldAlt,
  FaArrowRight,
  FaBrain,
  FaChartBar,
} from "react-icons/fa";
import useInView from "../hooks/useInView";

const Homepage = () => {
  const [heroRef, heroInView] = useInView();
  const [featuresRef, featuresInView] = useInView();
  const [socialProofRef, socialProofInView] = useInView();
  const [assistantRef, assistantInView] = useInView();
  const [ctaRef, ctaInView] = useInView();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
      {/* Hero Section */}
      <section ref={heroRef} className="relative py-20 px-4 sm:px-6 lg:px-8">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900/50 via-cyan-900/10 to-emerald-900/30"></div>
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h1
              className={`${
                heroInView ? "fade-in-up" : "opacity-0"
              } text-4xl md:text-7xl font-bold mb-6 md:mb-8 bg-gradient-to-r from-purple-400 to-cyan-100 bg-clip-text text-transparent leading-tight md:leading-relaxed px-2 md:px-4 py-1 md:py-2`}
            >
              Tu Cerebro Financiero Digital
            </h1>
            <p
              className={`${
                heroInView ? "fade-in-up stagger-1" : "opacity-0"
              } text-xl md:text-2xl text-gray-300 mb-12 max-w-3xl mx-auto`}
            >
              Optimiza tus finanzas con IA avanzada. Análisis predictivo,
              recomendaciones personalizadas y gestión inteligente de tu
              patrimonio.
            </p>
            <div
              className={`${
                heroInView ? "fade-in-up stagger-2" : "opacity-0"
              } flex flex-col sm:flex-row gap-4 justify-center`}
            >
              <Link
                to="/register"
                className="px-8 py-4 bg-gradient-to-r from-emerald-500 to-cyan-500 rounded-full font-semibold text-lg hover:from-emerald-600 hover:to-cyan-600 transition-all duration-300 transform hover:scale-105"
              >
                Comenzar Gratis
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Features Grid */}
      <section
        ref={featuresRef}
        className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-900/50"
      >
        <div className="max-w-7xl mx-auto">
          <h2
            className={`${
              featuresInView ? "fade-in-up" : "opacity-0"
            } text-4xl font-bold text-center mb-16 bg-gradient-to-r from-emerald-100 to-cyan-700 bg-clip-text text-transparent`}
          >
            Características Revolucionarias
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className={`${
                  featuresInView
                    ? `fade-in-up stagger-${(index % 3) + 1}`
                    : "opacity-0"
                } p-8 rounded-2xl bg-gradient-to-b from-gray-800/50 to-gray-900/50 border border-gray-700/50 backdrop-blur-sm hover:transform hover:scale-105 transition-all duration-300`}
              >
                <feature.icon className="text-4xl mb-4 text-cyan-400" />
                <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Social Proof Section */}
      <section
        ref={socialProofRef}
        className="py-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-gray-900/50 to-gray-800/50"
      >
        <div className="max-w-7xl mx-auto text-center">
          <h2
            className={`${
              socialProofInView ? "fade-in-up" : "opacity-0"
            } text-4xl font-bold mb-16 bg-gradient-to-r from-emerald-400 to-cyan-400 bg-clip-text text-transparent`}
          >
            Únete a miles de usuarios satisfechos
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            {stats.map((stat, index) => (
              <div
                key={index}
                className={`${
                  socialProofInView
                    ? `fade-in-up stagger-${index + 1}`
                    : "opacity-0"
                } p-6 rounded-2xl bg-gradient-to-b from-gray-800/50 to-gray-900/50 border border-gray-700/50`}
              >
                <div className="text-4xl font-bold text-emerald-400 mb-2">
                  {stat.value}
                </div>
                <div className="text-gray-300">{stat.label}</div>
              </div>
            ))}
          </div>

          <div
            className={`${
              socialProofInView ? "fade-in-up stagger-4" : "opacity-0"
            } grid grid-cols-1 md:grid-cols-3 gap-8`}
          >
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="p-6 rounded-2xl bg-gradient-to-b from-gray-800/50 to-gray-900/50 border border-gray-700/50"
              >
                <div className="text-gray-300 italic mb-4">
                  "{testimonial.text}"
                </div>
                <div className="font-semibold text-emerald-400">
                  {testimonial.author}
                </div>
                <div className="text-sm text-gray-400">{testimonial.role}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* AI Assistant Preview */}
      <section ref={assistantRef} className="py-20 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div
              className={`space-y-8 ${
                assistantInView ? "fade-in-left" : "opacity-0"
              }`}
            >
              <h2 className="text-4xl text-center font-bold bg-gradient-to-r from-emerald-400 to-cyan-400 bg-clip-text text-transparent">
                Tu nuevo AIsistente
              </h2>
              <p className="text-xl text-gray-300">
                Obtén respuestas instantáneas a tus preguntas financieras y
                recibe consejos personalizados basados en tus datos y objetivos.
              </p>
              <ul className="space-y-4">
                {aiFeatures.map((feature, index) => (
                  <li key={index} className="flex items-center gap-3">
                    <FaArrowRight className="text-emerald-400" />
                    <span className="text-gray-300">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={`relative ${
                assistantInView ? "fade-in-right" : "opacity-0"
              }`}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 to-cyan-500/20 blur-3xl"></div>
              <div className="relative rounded-2xl bg-gray-800/50 border border-gray-700/50 p-6 backdrop-blur-sm">
                <div className="chat-preview space-y-4">
                  {chatPreview.map((message, index) => (
                    <div
                      key={index}
                      className={`flex ${
                        message.type === "user"
                          ? "justify-end"
                          : "justify-start"
                      }`}
                    >
                      <div
                        className={`max-w-[80%] p-4 rounded-2xl ${
                          message.type === "user"
                            ? "bg-cyan-500/20 text-cyan-100"
                            : "bg-emerald-500/20 text-emerald-100"
                        }`}
                      >
                        {message.text}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section
        ref={ctaRef}
        className="py-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-r from-emerald-900/50 to-cyan-900/50"
      >
        <div
          className={`max-w-4xl mx-auto text-center ${
            ctaInView ? "fade-in-up" : "opacity-0"
          }`}
        >
          <h2 className="text-4xl font-bold mb-8 bg-gradient-to-r from-emerald-200 to-purple-400 bg-clip-text text-transparent">
            Comienza Tu Viaje Financiero Hoy
          </h2>
          <p className="text-xl text-gray-300 mb-12">
            Únete a miles de usuarios que ya están transformando su futuro
            financiero con inteligencia artificial.
          </p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-emerald-500 to-cyan-500 rounded-full font-semibold text-lg hover:from-emerald-600 hover:to-cyan-600 transition-all duration-300 transform hover:scale-105"
          >
            Crear Cuenta Gratuita
            <FaArrowRight className="ml-2" />
          </Link>
        </div>
      </section>
    </div>
  );
};

const features = [
  {
    icon: FaBrain,
    title: "IA Predictiva",
    description:
      "Análisis avanzado de patrones financieros para anticipar tendencias y optimizar decisiones.",
  },
  {
    icon: FaChartLine,
    title: "Gestión Patrimonial",
    description:
      "Seguimiento integral de activos y recomendaciones para maximizar tu patrimonio.",
  },
  {
    icon: FaRobot,
    title: "Asistente 24/7",
    description:
      "Respuestas instantáneas a tus consultas financieras en cualquier momento.",
  },
  {
    icon: FaChartBar,
    title: "Análisis Detallado",
    description:
      "Visualizaciones claras y métricas precisas de tu situación financiera.",
  },
  {
    icon: FaShieldAlt,
    title: "Seguridad Avanzada",
    description:
      "Protección de datos con encriptación de nivel bancario y autenticación multifactor.",
  },
  {
    icon: FaChartLine,
    title: "Objetivos Smart",
    description:
      "Establecimiento y seguimiento de metas financieras con ajustes dinámicos.",
  },
];

const aiFeatures = [
  "Análisis predictivo de gastos e ingresos",
  "Recomendaciones personalizadas de inversión",
  "Alertas inteligentes de gastos y oportunidades",
  "Planificación automatizada de presupuestos",
];

const chatPreview = [
  {
    type: "user",
    text: "¿Cómo puedo optimizar mis gastos mensuales?",
  },
  {
    type: "assistant",
    text: "Basado en tu historial, podrías ahorrar 300€ reduciendo gastos en suscripciones y optimizando tus compras de supermercado. ¿Quieres ver un plan detallado?",
  },
  {
    type: "user",
    text: "Sí, muéstrame el plan de optimización",
  },
];

const stats = [
  {
    value: "+50%",
    label: "Decisiones financieras mejoradas con IA",
  },
  {
    value: "+35%",
    label: "Ahorro promedio mensual",
  },
  {
    value: "98%",
    label: "Satisfacción",
  },
];

const testimonials = [
  {
    text: "Esta plataforma ha transformado completamente la manera en que manejo mis finanzas. He logrado aumentar mis ahorros en un 45% en solo 3 meses.",
    author: "María G.",
    role: "Emprendedora",
  },
  {
    text: "La mejor inversión que he hecho para mi futuro financiero. El asistente virtual me ayudó a reducir mis gastos en un 30% sin esfuerzo.",
    author: "Carlos R.",
    role: "Profesional IT",
  },
  {
    text: "Increíble cómo la IA puede hacer que la gestión financiera sea tan sencilla. He aumentado mis ahorros un 40% en 6 meses.",
    author: "Ana M.",
    role: "Diseñadora",
  },
];

export default Homepage;
