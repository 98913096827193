import React from "react";
import { Bar } from "react-chartjs-2";

const CompoundCalculator = ({
  capitalInicial,
  setCapitalInicial,
  aportacionMensual,
  setAportacionMensual,
  anos,
  setAnos,
  tasaInteres,
  setTasaInteres,
  considerarInflacion,
  setConsiderarInflacion,
  tasaInflacion,
  setTasaInflacion,
  resultado,
  ganado,
  dataChart,
  calculatorChartOptions,
  handleIncrement,
  handleDecrement,
}) => {
  return (
    <div className="bg-[var(--secondary-color)] p-4 md:p-6 rounded-lg border border-[var(--border-color)] shadow-md mb-16 md:mb-0 overflow-hidden">
      <h2 className="text-[var(--font-color)] text-xl md:text-2xl font-semibold mb-6">
        Calculadora de Interés Compuesto
      </h2>

      <div className="flex flex-col md:flex-row gap-6 md:gap-8">
        {/* Columna de inputs */}
        <div className="w-full md:w-[65%] flex flex-col gap-4">
          {/* Capital inicial */}
          <div className="flex flex-col gap-3">
            <label className="text-[var(--font-color)]">Capital inicial:</label>
            <div className="flex flex-col sm:flex-row items-center gap-2">
              <div className="relative flex-1">
                <input
                  type="number"
                  value={capitalInicial}
                  onChange={(e) => setCapitalInicial(e.target.value)}
                  className="w-full h-10 px-3 bg-transparent border border-[var(--border-color)] text-[var(--font-color)] rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-[var(--primary-color)] focus:border-transparent"
                />
                <span className="absolute right-3 top-1/2 -translate-y-1/2 text-[var(--font-color)]">
                  €
                </span>
              </div>
              <div className="flex items-center gap-1 ml-4">
                <button
                  onClick={handleIncrement(
                    setCapitalInicial,
                    capitalInicial,
                    1000
                  )}
                  className="w-10 h-10 flex items-center justify-center bg-transparent border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg hover:bg-[var(--bg-hover)] transition-colors"
                >
                  +
                </button>
                <button
                  onClick={handleDecrement(
                    setCapitalInicial,
                    capitalInicial,
                    1000
                  )}
                  className="w-10 h-10 flex items-center justify-center bg-transparent border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg hover:bg-[var(--bg-hover)] transition-colors"
                >
                  -
                </button>
              </div>
            </div>
          </div>

          {/* Resumen de la inversión */}
          <div className="bg-[var(--bg-secondary)] rounded-lg p-4 border border-[var(--border-color)] mt-6">
            <h3 className="text-[var(--font-color)] text-lg font-semibold mb-4">
              Resumen de la inversión
            </h3>
            <div className="space-y-3">
              <div className="flex justify-between items-center py-2 border-b border-[var(--border-color)]">
                <span className="text-[var(--text-secondary)]">
                  Valor final
                </span>
                <span className="text-[var(--primary-color)] font-semibold">
                  {resultado}€
                </span>
              </div>
              <div className="flex justify-between items-center py-2 border-b border-[var(--border-color)]">
                <span className="text-[var(--text-secondary)]">
                  Ganancias totales
                </span>
                <span className="text-[#6dd08c] font-semibold">{ganado}€</span>
              </div>
              <div className="flex justify-between items-center py-2">
                <span className="text-[var(--text-secondary)]">Plazo</span>
                <span className="text-[var(--font-color)] font-semibold">
                  {anos} años
                </span>
              </div>
            </div>
          </div>

          {/* Checkbox de inflación */}
          <div className="flex items-center gap-2 mt-4">
            <input
              type="checkbox"
              checked={considerarInflacion}
              onChange={(e) => setConsiderarInflacion(e.target.checked)}
              className="w-4 h-4 rounded border-[var(--border-color)] bg-transparent checked:bg-[var(--primary-color)] checked:border-transparent focus:ring-[var(--primary-color)] focus:ring-offset-0"
            />
            <label className="text-[var(--font-color)] text-sm">
              Considerar inflación
            </label>
          </div>

          {considerarInflacion && (
            <div className="flex flex-col gap-2">
              <label className="text-[var(--font-color)] text-sm">
                Tasa de inflación (%)
              </label>
              <input
                type="number"
                value={tasaInflacion}
                onChange={(e) => setTasaInflacion(e.target.value)}
                className="w-full sm:w-32 h-10 px-3 bg-transparent border border-[var(--border-color)] text-[var(--font-color)] rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-[var(--primary-color)] focus:border-transparent"
              />
            </div>
          )}
        </div>

        {/* Gráfico */}
        <div className="w-full md:w-[35%]">
          {dataChart.nominal && (
            <div className="min-w-[300px] h-[400px]">
              <Bar
                data={{
                  labels: Array.from({ length: anos }, (_, i) => i + 1),
                  datasets: [
                    {
                      label: "Balance nominal",
                      data: dataChart.nominal,
                      backgroundColor: "rgba(75, 192, 192, 1)",
                      borderColor: "rgba(255, 255, 255, 0.1)",
                      borderWidth: 1,
                    },
                    ...(dataChart.real
                      ? [
                          {
                            label: "Balance real (ajustado por inflación)",
                            data: dataChart.real,
                            backgroundColor: "rgba(255, 99, 132, 0.8)",
                            borderColor: "rgba(255, 255, 255, 0.1)",
                            borderWidth: 1,
                          },
                        ]
                      : []),
                  ],
                }}
                options={calculatorChartOptions}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompoundCalculator;
