import React, { useState, useEffect, useCallback } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const FinancialProjection = ({
  currentIncomes,
  currentExpenses,
  currentInvestments,
  currentPatrimony,
}) => {
  const [formData, setFormData] = useState({
    initialCapital: currentPatrimony || 0,
    monthlyIncome: currentIncomes || 0,
    monthlyExpenses: currentExpenses || 0,
    monthlyInvestment: currentInvestments || 0,
    yearsToProject: 25,
    expectedReturn: 7,
  });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      initialCapital: currentPatrimony,
      monthlyIncome: currentIncomes,
      monthlyExpenses: currentExpenses,
      monthlyInvestment: currentInvestments,
    }));
  }, [currentIncomes, currentExpenses, currentInvestments, currentPatrimony]);

  const [projectionData, setProjectionData] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: [
        "initialCapital",
        "monthlyIncome",
        "monthlyExpenses",
        "monthlyInvestment",
        "yearsToProject",
        "expectedReturn",
      ].includes(name)
        ? parseFloat(value) || 0
        : value,
    }));
  };

  const calculateProjection = useCallback(() => {
    const years = Array.from(
      { length: parseInt(formData.yearsToProject) + 1 },
      (_, i) => i
    );

    const projectedValues = years.map((currentYear) => {
      const monthlyNet =
        parseFloat(formData.monthlyIncome) -
        parseFloat(formData.monthlyExpenses) -
        parseFloat(formData.monthlyInvestment);
      const totalSavings = monthlyNet * 12 * currentYear;

      let dineroActual = parseFloat(formData.initialCapital);
      const tasa = parseFloat(formData.expectedReturn);
      const adicionMensual = parseFloat(formData.monthlyInvestment);

      for (let i = 0; i < currentYear * 12; i++) {
        dineroActual += adicionMensual;
        dineroActual *= 1 + tasa / 100 / 12;
      }

      const investmentValue = dineroActual;
      const totalValue = totalSavings + investmentValue;

      return {
        year: currentYear,
        value: totalValue.toFixed(2),
        savings: totalSavings.toFixed(2),
        investments: investmentValue.toFixed(2),
      };
    });

    return {
      labels: years.map((year) => `Año ${year}`),
      datasets: [
        {
          label: "Ahorros sin interés",
          data: projectedValues.map((item) => item.savings),
          fill: false,
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
        },
        {
          label: "Inversiones con interés compuesto",
          data: projectedValues.map((item) => item.investments),
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
        {
          label: "Total",
          data: projectedValues.map((item) => item.value),
          fill: false,
          borderColor: "rgb(153, 102, 255)",
          tension: 0.1,
        },
      ],
    };
  }, [formData]);

  useEffect(() => {
    setProjectionData(calculateProjection());
  }, [calculateProjection]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 12,
          padding: 10,
          font: {
            size: 11,
          },
          color: "rgb(243, 244, 246)",
        },
      },
      title: {
        display: true,
        text: "Proyección Financiera a Futuro",
        font: {
          size: 14,
        },
        color: "rgb(243, 244, 246)",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(55, 65, 81, 1)",
        },
        ticks: {
          callback: function (value) {
            return new Intl.NumberFormat("es-ES", {
              style: "currency",
              currency: "EUR",
              notation: "compact",
            }).format(value);
          },
          font: {
            size: 10,
          },
          color: "rgb(243, 244, 246)",
        },
      },
      x: {
        grid: {
          color: "rgba(55, 65, 81, 1)",
        },
        ticks: {
          font: {
            size: 10,
          },
          color: "rgb(243, 244, 246)",
        },
      },
    },
  };

  return (
    <div className="p-4 w-full">
      <div className="grid grid-cols-1 gap-4">
        <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
          <h2 className="text-xl font-semibold text-[var(--text-primary)] mb-2">
            Calculadora de Proyección Financiera
          </h2>
          <p className="text-[var(--text-secondary)] text-sm">
            Simula tu futuro financiero ajustando los parámetros de ingresos,
            gastos e inversiones. Visualiza cómo el interés compuesto y la
            inflación pueden afectar a tu patrimonio a largo plazo.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
            <div className="space-y-4">
              <div>
                <label className="text-[var(--text-secondary)] text-sm block mb-2">
                  Patrimonio Actual (€)
                </label>
                <input
                  type="number"
                  name="initialCapital"
                  value={formData.initialCapital}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg focus:ring-2 focus:ring-[var(--accent-color)] transition-all"
                />
              </div>
              <div>
                <label className="text-[var(--text-secondary)] text-sm block mb-2">
                  Ingresos Mensuales (€)
                </label>
                <input
                  type="number"
                  name="monthlyIncome"
                  value={formData.monthlyIncome}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg focus:ring-2 focus:ring-[var(--accent-color)] transition-all"
                />
              </div>
            </div>
          </div>

          <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
            <div className="space-y-4">
              <div>
                <label className="text-[var(--text-secondary)] text-sm block mb-2">
                  Gastos Mensuales (€)
                </label>
                <input
                  type="number"
                  name="monthlyExpenses"
                  value={formData.monthlyExpenses}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg focus:ring-2 focus:ring-[var(--accent-color)] transition-all"
                />
              </div>
              <div>
                <label className="text-[var(--text-secondary)] text-sm block mb-2">
                  Inversión Mensual (€)
                </label>
                <input
                  type="number"
                  name="monthlyInvestment"
                  value={formData.monthlyInvestment}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg focus:ring-2 focus:ring-[var(--accent-color)] transition-all"
                />
              </div>
            </div>
          </div>

          <div className="bg-gradient-primary rounded-xl p-4 shadow-[var(--card-shadow)]">
            <div className="space-y-4">
              <div>
                <label className="text-[var(--text-primary)] text-sm block mb-2">
                  Años a Proyectar
                </label>
                <input
                  type="number"
                  name="yearsToProject"
                  value={formData.yearsToProject}
                  min="1"
                  max="50"
                  onChange={handleInputChange}
                  className="w-full p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg focus:ring-2 focus:ring-[var(--accent-color)] transition-all"
                />
              </div>
              <div>
                <label className="text-[var(--text-primary)] text-sm block mb-2">
                  Retorno Anual Esperado (%)
                </label>
                <input
                  type="number"
                  name="expectedReturn"
                  value={formData.expectedReturn}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-[var(--bg-primary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg focus:ring-2 focus:ring-[var(--accent-color)] transition-all"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
          <div className="h-[400px]">
            {projectionData && <Line options={options} data={projectionData} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialProjection;
