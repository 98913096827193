import React from "react";
import { useNavigate } from "react-router-dom";
import UserInfo from "./UserInfo";
import Sidebar from "./Sidebar";

const Settings = ({
  activeSection,
  setActiveSection,
  handleLogout,
  setShowDeleteConfirm,
  userData,
}) => {
  const navigate = useNavigate();

  const handleSectionChange = (section) => {
    setActiveSection(section);
    if (section !== "settings") {
      navigate("/dashboard");
    }
  };

  return (
    <div className="flex min-h-screen bg-[var(--bg-primary)]">
      <Sidebar
        activeSection={activeSection}
        setActiveSection={handleSectionChange}
        handleLogout={handleLogout}
        setShowDeleteConfirm={setShowDeleteConfirm}
        userData={userData}
      />

      <div className="flex-1 md:ml-[250px] p-4">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-2xl font-bold text-[var(--text-primary)] mb-6">
            Ajustes de cuenta
          </h1>
          <UserInfo userData={userData} />
        </div>
      </div>
    </div>
  );
};

export default Settings;
