import React from "react";
import { FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { Doughnut } from "react-chartjs-2";
import { formatCurrency } from "../utils/format";

const MonthlyIncomes = ({
  incomes,
  editingIncomeIndex,
  handleIncomeEdit,
  handleIncomeUpdate,
  handleIncomeSave,
  handleIncomeDelete,
  showNewIncomeForm,
  toggleNewIncomeForm,
  newIncomeName,
  setNewIncomeName,
  newIncomeAmount,
  setNewIncomeAmount,
  addIncome,
  setEditingIncomeIndex,
}) => {
  const totalIncomes = incomes.reduce((acc, income) => acc + income.amount, 0);

  return (
    <div className="p-4 w-full">
      <div className="grid grid-cols-1 gap-4">
        {/* Título y Total */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Título */}
          <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
            <h2 className="text-xl font-semibold text-[var(--text-primary)]">
              Ingresos Mensuales
            </h2>
          </div>

          {/* Total de Ingresos */}
          <div className="md:col-span-2 bg-gradient-primary rounded-xl p-4 shadow-[var(--card-shadow)]">
            <h3 className="text-lg text-[var(--text-primary)]">
              Total Ingresos
            </h3>
            <p className="text-3xl font-bold text-[var(--text-primary)]">
              {formatCurrency(totalIncomes)}
            </p>
          </div>
        </div>

        {/* Lista de Ingresos y Gráfico */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Lista de Ingresos - ocupa 2 columnas */}
          <div className="md:col-span-2 bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
            <div className="space-y-3">
              {incomes.map((income, index) => (
                <div
                  key={index}
                  className="bg-[var(--bg-primary)] rounded-lg p-3 hover:bg-[var(--bg-hover)] transition-colors"
                >
                  {editingIncomeIndex === index ? (
                    <div className="flex flex-col sm:flex-row items-center gap-3">
                      <div className="flex flex-col sm:flex-row gap-3 w-full items-center">
                        <input
                          type="text"
                          value={income.name}
                          onChange={(e) =>
                            handleIncomeUpdate(index, "name", e.target.value)
                          }
                          className="w-full p-2 bg-[var(--bg-secondary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                          placeholder="Nombre"
                        />
                        <input
                          type="number"
                          value={income.amount}
                          onChange={(e) =>
                            handleIncomeUpdate(index, "amount", e.target.value)
                          }
                          className="w-full sm:w-32 p-2 bg-[var(--bg-secondary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                          placeholder="Cantidad"
                        />
                      </div>
                      <div className="flex gap-2 w-full sm:w-auto">
                        <button
                          onClick={() => handleIncomeSave(index)}
                          className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                        >
                          Guardar
                        </button>
                        <button
                          onClick={() => setEditingIncomeIndex(null)}
                          className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-between">
                      <span className="text-[var(--text-primary)]">
                        {income.name}:{" "}
                        <span className="text-[var(--accent-color)]">
                          {formatCurrency(income.amount)}
                        </span>
                      </span>
                      <div className="flex gap-2">
                        <button
                          onClick={() => handleIncomeEdit(index)}
                          className="text-[var(--text-secondary)] hover:text-[var(--accent-color)] transition-colors bg-transparent"
                        >
                          <FaPencilAlt className="text-sm" />
                        </button>
                        <button
                          onClick={() => handleIncomeDelete(index)}
                          className="text-[var(--text-secondary)] hover:text-red-400 transition-colors bg-transparent"
                        >
                          <FaTrash className="text-sm" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}

              {showNewIncomeForm ? (
                <div className="bg-[var(--bg-primary)] rounded-lg p-3">
                  <div className="flex flex-col sm:flex-row items-center gap-3">
                    <div className="flex flex-col sm:flex-row gap-3 w-full items-center">
                      <input
                        type="text"
                        value={newIncomeName}
                        onChange={(e) => setNewIncomeName(e.target.value)}
                        placeholder="Nombre del ingreso"
                        className="w-full p-2 bg-[var(--bg-secondary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                      />
                      <input
                        type="number"
                        value={newIncomeAmount}
                        onChange={(e) => setNewIncomeAmount(e.target.value)}
                        placeholder="Cantidad"
                        className="w-full sm:w-32 p-2 bg-[var(--bg-secondary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                      />
                    </div>
                    <div className="flex gap-2 w-full sm:w-auto">
                      <button
                        onClick={addIncome}
                        className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                      >
                        <FaPlus className="text-sm inline mr-2" /> Añadir
                      </button>
                      <button
                        onClick={toggleNewIncomeForm}
                        className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <button
                  onClick={toggleNewIncomeForm}
                  className="w-full p-3 bg-[var(--bg-secondary)] rounded-lg text-[var(--text-secondary)] hover:bg-[var(--bg-hover)] hover:text-[var(--text-primary)] transition-all flex items-center justify-center gap-2"
                >
                  <FaPlus /> Añadir ingreso
                </button>
              )}
            </div>
          </div>

          {/* Gráfico - ocupa 1 columna */}
          <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
            {incomes.length > 0 ? (
              <div className="relative">
                <Doughnut
                  data={{
                    labels: incomes.map((income) => income.name),
                    datasets: [
                      {
                        data: incomes.map((income) => income.amount),
                        backgroundColor: [
                          "rgb(16, 185, 129)", // emerald-500
                          "rgb(168, 85, 247)", // purple-500
                          "rgb(236, 72, 153)", // pink-500
                          "rgb(249, 115, 22)", // orange-500
                          "rgb(234, 179, 8)", // yellow-500
                          "rgb(14, 165, 233)", // sky-500
                          "rgb(79, 70, 229)", // indigo-600
                        ],
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={{
                    rotation: -90,
                    circumference: 180,
                    cutout: "75%",
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            const label = context.label || "";
                            const value = context.raw || 0;
                            const total = context.dataset.data.reduce(
                              (acc, data) => acc + data,
                              0
                            );
                            const percentage = ((value / total) * 100).toFixed(
                              2
                            );
                            return `${label}: ${formatCurrency(
                              value
                            )} (${percentage}%)`;
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            ) : (
              <p className="text-center text-[var(--text-secondary)]">
                No hay ingresos registrados
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyIncomes;
