import React from "react";
import { FaPencilAlt, FaPlus, FaTrash, FaFilter } from "react-icons/fa";
import { Doughnut } from "react-chartjs-2";
import { formatCurrency } from "../utils/format";

const MonthlyExpenses = ({
  expenses,
  editingExpenseIndex,
  handleExpenseEdit,
  handleExpenseUpdate,
  handleExpenseSave,
  handleExpenseDelete,
  showNewExpenseForm,
  toggleNewExpenseForm,
  newExpenseName,
  setNewExpenseName,
  newExpenseAmount,
  setNewExpenseAmount,
  addExpense,
  showCategoryFilter,
  setShowCategoryFilter,
  selectedCategory,
  handleCategorySelect,
  categories,
  filteredExpenses,
  setEditingExpenseIndex,
}) => {
  const totalExpenses = expenses.reduce(
    (acc, expense) => acc + expense.amount,
    0
  );

  return (
    <div className="p-4 w-full">
      <div className="grid grid-cols-1 gap-4">
        {/* Título y Total */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Título */}
          <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
            <h2 className="text-xl font-semibold text-[var(--text-primary)]">
              Gastos Mensuales
            </h2>
          </div>

          {/* Total de Gastos */}
          <div className="md:col-span-2 bg-gradient-primary rounded-xl p-4 shadow-[var(--card-shadow)]">
            <h3 className="text-lg text-[var(--text-primary)]">Total Gastos</h3>
            <p className="text-3xl font-bold text-[var(--text-primary)]">
              {formatCurrency(totalExpenses)}
            </p>
          </div>
        </div>

        {/* Lista de Gastos y Gráfico */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Lista de Gastos - ocupa 2 columnas */}
          <div className="md:col-span-2 bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
            <div className="flex justify-between items-center mb-4">
              <button
                className="text-[var(--text-secondary)] hover:text-[var(--accent-color)] transition-colors bg-transparent"
                onClick={() => setShowCategoryFilter(!showCategoryFilter)}
              >
                <FaFilter className="text-sm" />
              </button>
            </div>

            {/* Filtro de categorías */}
            {showCategoryFilter && (
              <div className="mb-4 p-2 bg-[var(--bg-primary)] rounded-lg border border-[var(--border-color)]">
                <div className="flex flex-wrap gap-2">
                  <button
                    className={`px-3 py-1 rounded-lg transition-colors ${
                      selectedCategory === "all"
                        ? "bg-[var(--accent-color)] text-[var(--text-primary)]"
                        : "bg-[var(--bg-secondary)] text-[var(--text-secondary)] hover:bg-[var(--bg-hover)]"
                    }`}
                    onClick={() => handleCategorySelect("all")}
                  >
                    Todas
                  </button>
                  {categories.map((category) => (
                    <button
                      key={category}
                      className={`px-3 py-1 rounded-lg transition-colors ${
                        selectedCategory === category
                          ? "bg-[var(--accent-color)] text-[var(--text-primary)]"
                          : "bg-[var(--bg-secondary)] text-[var(--text-secondary)] hover:bg-[var(--bg-hover)]"
                      }`}
                      onClick={() => handleCategorySelect(category)}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div className="space-y-3">
              {filteredExpenses.map((expense, index) => (
                <div
                  key={index}
                  className="bg-[var(--bg-primary)] rounded-lg p-3 hover:bg-[var(--bg-hover)] transition-colors"
                >
                  {editingExpenseIndex === index ? (
                    <div className="flex flex-col sm:flex-row items-center gap-3">
                      <div className="flex flex-col sm:flex-row gap-3 w-full items-center">
                        <input
                          type="text"
                          value={expense.name}
                          onChange={(e) =>
                            handleExpenseUpdate(index, "name", e.target.value)
                          }
                          className="w-full p-2 bg-[var(--bg-secondary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                          placeholder="Nombre"
                        />
                        <input
                          type="number"
                          value={expense.amount}
                          onChange={(e) =>
                            handleExpenseUpdate(index, "amount", e.target.value)
                          }
                          className="w-full sm:w-32 p-2 bg-[var(--bg-secondary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                          placeholder="Cantidad"
                        />
                      </div>
                      <div className="flex gap-2 w-full sm:w-auto">
                        <button
                          onClick={() => handleExpenseSave(index)}
                          className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                        >
                          Guardar
                        </button>
                        <button
                          onClick={() => setEditingExpenseIndex(null)}
                          className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-between">
                      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 min-w-0 flex-1 mr-2">
                        <span className="text-[var(--text-primary)] truncate max-w-[150px] sm:max-w-none">
                          {expense.name}
                        </span>
                        <div className="flex flex-wrap items-center gap-2">
                          <span className="text-xs px-2 py-1 bg-[var(--bg-secondary)] rounded-full text-[var(--text-secondary)] whitespace-nowrap">
                            {expense.category}
                          </span>
                          <span className="text-[var(--accent-color)] whitespace-nowrap">
                            {formatCurrency(expense.amount)}
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-2 flex-shrink-0">
                        <button
                          onClick={() => handleExpenseEdit(index)}
                          className="text-[var(--text-secondary)] hover:text-[var(--accent-color)] transition-colors bg-transparent"
                        >
                          <FaPencilAlt className="text-sm" />
                        </button>
                        <button
                          onClick={() => handleExpenseDelete(index)}
                          className="text-[var(--text-secondary)] hover:text-red-400 transition-colors bg-transparent"
                        >
                          <FaTrash className="text-sm" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}

              {showNewExpenseForm ? (
                <div className="bg-[var(--bg-primary)] rounded-lg p-3">
                  <div className="flex flex-col sm:flex-row items-center gap-3">
                    <div className="flex flex-col sm:flex-row gap-3 w-full items-center">
                      <input
                        type="text"
                        value={newExpenseName}
                        onChange={(e) => setNewExpenseName(e.target.value)}
                        placeholder="Nombre del gasto"
                        className="w-full p-2 bg-[var(--bg-secondary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                      />
                      <input
                        type="number"
                        value={newExpenseAmount}
                        onChange={(e) => setNewExpenseAmount(e.target.value)}
                        placeholder="Cantidad"
                        className="w-full sm:w-32 p-2 bg-[var(--bg-secondary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg text-center"
                      />
                    </div>
                    <div className="flex gap-2 w-full sm:w-auto">
                      <button
                        onClick={addExpense}
                        className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                      >
                        <FaPlus className="text-sm inline mr-2" /> Añadir
                      </button>
                      <button
                        onClick={toggleNewExpenseForm}
                        className="flex-1 sm:flex-initial text-[var(--text-primary)] hover:text-[var(--accent-color)] border border-[var(--border-color)] rounded-lg px-4 py-2 transition-colors bg-transparent"
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <button
                  onClick={toggleNewExpenseForm}
                  className="w-full p-3 bg-[var(--bg-secondary)] rounded-lg text-[var(--text-secondary)] hover:bg-[var(--bg-hover)] hover:text-[var(--text-primary)] transition-all flex items-center justify-center gap-2"
                >
                  <FaPlus /> Añadir gasto
                </button>
              )}
            </div>
          </div>

          {/* Gráfico - ocupa 1 columna */}
          <div className="bg-[var(--bg-secondary)] rounded-xl p-4 border border-[var(--border-color)] shadow-[var(--card-shadow)]">
            {expenses.length > 0 ? (
              <div className="relative">
                <Doughnut
                  data={{
                    labels: expenses.map((expense) => expense.name),
                    datasets: [
                      {
                        data: expenses.map((expense) => expense.amount),
                        backgroundColor: [
                          "rgb(16, 185, 129)", // emerald-500
                          "rgb(168, 85, 247)", // purple-500
                          "rgb(236, 72, 153)", // pink-500
                          "rgb(249, 115, 22)", // orange-500
                          "rgb(234, 179, 8)", // yellow-500
                          "rgb(14, 165, 233)", // sky-500
                          "rgb(79, 70, 229)", // indigo-600
                        ],
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={{
                    rotation: -90,
                    circumference: 180,
                    cutout: "75%",
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            const label = context.label || "";
                            const value = context.raw || 0;
                            const total = context.dataset.data.reduce(
                              (acc, data) => acc + data,
                              0
                            );
                            const percentage = ((value / total) * 100).toFixed(
                              2
                            );
                            return `${label}: ${formatCurrency(
                              value
                            )} (${percentage}%)`;
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            ) : (
              <p className="text-center text-[var(--text-secondary)]">
                No hay gastos registrados
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyExpenses;
