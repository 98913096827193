import React, { useState, useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import axios from "axios";
import { buildUrl } from "../utils/url";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const predefinedQuestions = [
    "¿Cuáles son los principios básicos para empezar a invertir?",
    "¿Cómo puedo crear un presupuesto mensual efectivo?",
    "¿Qué es la estrategia de Montecarlo?",
    "¿Cuál es la diferencia entre interés simple y compuesto?",
  ];

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise && window.MathJax.typesetPromise();
    }
  }, [messages]);

  const handleSubmit = async (e, predefinedMessage = null) => {
    if (e) e.preventDefault();
    const messageToSend = predefinedMessage || inputMessage;
    if (!messageToSend.trim() || isLoading) return;

    try {
      setIsLoading(true);
      setInputMessage("");

      const response = await axios({
        method: "POST",
        url: buildUrl(process.env.REACT_APP_API_URL, "api"),
        data: {
          message: messageToSend,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        timeout: 30000,
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        },
        maxRedirects: 0,
        proxy: false,
      });

      if (!response.data.success)
        throw new Error("Error en la respuesta del servidor");

      setMessages((prev) => [
        ...prev,
        { role: "user", content: messageToSend },
        { role: "assistant", content: response.data.message },
      ]);
    } catch (error) {
      console.error("Error en chat:", {
        message: error.message,
        code: error.code,
        response: error.response?.data,
        config: error.config,
      });
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content:
            "Lo siento, ha ocurrido un error. Por favor, inténtalo de nuevo.",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios({
        method: "POST",
        url: buildUrl(process.env.REACT_APP_API_URL, "api/analyze-file"),
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
        timeout: 30000,
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        },
        maxRedirects: 0,
        proxy: false,
      });

      if (!response.data.success)
        throw new Error("Error al analizar el archivo");

      setMessages((prev) => [
        ...prev,
        { role: "user", content: `Analizando archivo: ${file.name}` },
        { role: "assistant", content: response.data.message },
      ]);
    } catch (error) {
      console.error("Error al analizar archivo:", {
        message: error.message,
        code: error.code,
        response: error.response?.data,
        config: error.config,
      });
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content:
            "Error al analizar el archivo. Por favor, inténtalo de nuevo.",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const sanitizeAndRenderHTML = (content) => {
    const sanitizedHTML = DOMPurify.sanitize(content, {
      ALLOWED_TAGS: [
        "blockquote",
        "p",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "ul",
        "ol",
        "li",
        "strong",
        "em",
        "b",
        "i",
        "a",
        "br",
        "span",
        "div",
      ],
      ADD_ATTR: ["class"],
    });
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isLoading]);

  return (
    <div className="bg-[var(--secondary-color)] p-4 md:p-6 rounded-lg border border-[var(--border-color)] shadow-md mb-16 md:mb-0 overflow-hidden">
      <h2 className="text-gradient text-xl md:text-2xl font-semibold mb-2">
        Asistente Virtual
      </h2>
      <p className="text-[var(--text-secondary)] text-sm mb-6">
        Pregunta cualquier duda sobre finanzas personales
      </p>

      <div className="flex flex-col gap-4">
        {messages.length === 0 && (
          <div className="flex flex-col gap-2">
            {predefinedQuestions.map((question, index) => (
              <button
                key={index}
                onClick={() => handleSubmit(null, question)}
                className="w-full p-3 bg-[var(--bg-secondary)] border border-[var(--border-color)] rounded-lg text-[var(--text-primary)] text-left hover:bg-[var(--bg-hover)] transition-all duration-300"
                disabled={isLoading}
              >
                {question}
              </button>
            ))}
          </div>
        )}

        <div
          className={`${
            messages.length === 0 ? "h-0" : "h-[50vh] md:h-[400px]"
          } overflow-y-auto`}
        >
          <div className="flex flex-col gap-3 p-2">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`p-3 rounded-lg ${
                  message.role === "user"
                    ? "bg-[var(--bg-secondary)] ml-auto"
                    : "bg-[var(--bg-secondary)] mr-auto"
                } border border-[var(--border-color)] max-w-[80%]`}
              >
                <div className="text-sm break-words">
                  {message.role === "assistant"
                    ? sanitizeAndRenderHTML(message.content)
                    : message.content}
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex justify-center items-center p-4 bg-[var(--bg-secondary)] rounded-lg border border-[var(--border-color)] max-w-[80%] mx-auto">
                <div className="chat-spinner"></div>
                <span className="ml-3 text-sm text-[var(--text-secondary)]">
                  Pensando...
                </span>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Escribe tu pregunta..."
              disabled={isLoading}
              className="flex-1 p-3 bg-[var(--bg-secondary)] border border-[var(--border-color)] text-[var(--text-primary)] rounded-lg"
            />
            <label className="p-3 flex-shrink-0 bg-[var(--bg-secondary)] border border-[var(--border-color)] rounded-lg cursor-pointer hover:bg-[var(--bg-hover)] flex items-center justify-center">
              📎
              <input
                type="file"
                onChange={handleFileUpload}
                accept=".pdf,.txt,.doc,.docx"
                className="hidden"
                disabled={isLoading}
              />
            </label>
          </div>
          <button
            type="submit"
            disabled={isLoading || !inputMessage.trim()}
            className="p-3 bg-[var(--primary-color)] text-white rounded-lg hover:bg-opacity-90 transition-colors flex items-center justify-center gap-2"
          >
            {isLoading ? (
              <>
                <div className="chat-spinner w-5 h-5 border-2"></div>
                <span>Enviando...</span>
              </>
            ) : (
              "Enviar"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Chatbot;
